/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { instanceOf } from "prop-types";
import moment from "moment";

const ListOffersFeedback = ({ listOffer, className }) => {
  const [page, setPage] = useState(1);
  const [offers, setOffers] = useState([]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
  };
  useEffect(() => {
    if (listOffer?.length >= 1) {
      const max = page * 10;
      const min = max - 10;
      const dataFilter = [];
      for (let index = min; index < listOffer.length; index++) {
        const element = listOffer[index];
        if (dataFilter.length <= 9) {
          dataFilter.push(element);
        }
      }
      setOffers(dataFilter);
    }
  }, [listOffer, page]);
  return (
    <div className={`agents insuransers offers-${className}`}>
      <div className="insuransers_table">
        <Table sticky dataSource={offers}>
          <Column
            title="Número de poliza"
            key="policyNumber"
            dataIndex="policyNumber"
            className="item"
          />
          <Column
            title="Aseguradora"
            key="insuranceCarrier"
            className="item-name"
            render={(_, record) => {
              return <span>{record?.insuranceCarrier?.name}</span>;
            }}
          />
          <Column
            title="Fecha de creación"
            key="createdAt"
            className="item"
            render={(_, record) => {
              return (
                <span>{moment(record?.createdAt).format("YYYY/MM/DD")}</span>
              );
            }}
          />
          <Column
            title="Estado"
            key="state"
            className="item"
            render={(_, record) => {
              return (
                <>
                  <span>{record?.offerState === "sent" && "Enviada"}</span>
                  <span>
                    {record?.offerState === "quotation" && "Cotización"}
                  </span>
                  <span>
                    {record?.offerState === "viwed" && "Visualizada "}
                  </span>
                  <span>
                    {record?.offerState === "feedback" && "Finalizada"}
                  </span>
                  <span>
                    {record?.offerState === "completed" && "Finalizada"}
                  </span>
                  <span>
                    {record?.offerState === "feedback_negative" && "Finalizada"}
                  </span>
                </>
              );
            }}
          />
          {className === "feedback" && (
            <Column
              title="Cotización"
              key="piaFiles"
              width={200}
              className="item item-feedback"
              render={(_, record) => {
                return (
                  record.piaFiles[0]?.url && (
                    <a
                      className="donwload-file"
                      target="_blank"
                      href={`${record.piaFiles[0]?.url}`}
                      rel="noopener noreferrer"
                      download={`${record.id}`}
                    >
                      Ver cotización
                    </a>
                  )
                );
              }}
            />
          )}
        </Table>
        <Pagination
          defaultCurrent={page}
          total={listOffer?.length}
          pageSize={10}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
ListOffersFeedback.propTypes = {
  listOffer: instanceOf(Object).isRequired,
  className: instanceOf(String).isRequired,
};
export default ListOffersFeedback;
