import { instanceOf } from "prop-types";
import React from "react";

const CardRenovations = ({ item }) => {
  return (
    <section className="CardRenovations">
      <div className="box box-top">
        <h1 className="title title-gradient">{item?.quantityOfExpirations}</h1>
        <p>Pólizas por vencer</p>
      </div>
      <div className="box box--medium">
        <div className="card">
          <img src={item?.image} alt="insurance" className="insurance" />
        </div>
        <p>Aseguradora top</p>
      </div>
      <div className="box box-bottom">
        <h1 className="title">{item?.daysToFirstExpiration}</h1>
        <p>Días de próximo vencimiento</p>
      </div>
    </section>
  );
};
CardRenovations.propTypes = {
  item: instanceOf(Object).isRequired,
};
export default CardRenovations;
