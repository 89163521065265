/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Input, Pagination, Table, Button } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import ModalLoader from "components/loader/ModalLoader";
import { func, number } from "prop-types";
import donwloadSvg from "assets/images/donwload-purple-svg.svg";
import moment from "moment";
import searchSvg from "assets/images/Search-purple.svg";
import closeSvg from "assets/images/close.svg";
import offersActions from "services/offers/actions";

const ListQuotes = ({ onChangePage, pageQuotes }) => {
  const dispatch = useDispatch();
  const { listQuotes } = useSelector((state) => state.offers.success);
  const { listQuotes: listQuotesLoading } = useSelector(
    (state) => state.offers.loading
  );

  const [isListQuotes, serListQuotes] = useState();
  const [objectSearch, setObjectSearch] = useState("");

  useEffect(() => {
    const newList = [];
    for (let index = 0; index < listQuotes?.items?.length; index++) {
      const element = listQuotes?.items[index];
      newList.push({
        key: element?.id,
        id: element?.piaFiles[0]?.id,
        numberPolicy: element?.policyNumber,
        agentName: element?.agent?.user?.person?.name,
        linkFile: element?.piaFiles[0]?.url,
        dateUpload: element?.createdAt,
        insurance: element?.insuranceCarrier?.name,
      });
    }
    serListQuotes(newList);
  }, [listQuotes]);

  const onChange = (pageNumber) => {
    onChangePage(pageNumber, "quotes");
  };

  const handleWriting = (e) => {
    setObjectSearch(e.target.value);
  };

  const handleSearch = (policy) => {
    const values = { pageQuotes: 1, policy };
    dispatch(offersActions.getQuotes(values));
  };

  const handleClear = () => {
    setObjectSearch("");
    const values = { pageQuotes };
    dispatch(offersActions.getQuotes(values));
  };
  return (
    <div>
      <div className="insuransers-head">
        <h1 className="agents__title">Cotizaciones</h1>
        <Input
          placeholder="Busca por numero de poliza"
          className="search-line"
          type="number"
          value={objectSearch}
          onChange={(e) => handleWriting(e)}
          suffix={
            <>
              {objectSearch !== "" && (
                <Button onClick={() => handleClear()}>
                  <img
                    src={closeSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              )}
              <Button onClick={() => handleSearch(objectSearch)}>
                <img
                  src={searchSvg}
                  className="site-form-item-icon"
                  alt="searcg"
                />
              </Button>
            </>
          }
        />
      </div>
      <Table sticky dataSource={isListQuotes}>
        <Column
          title="Numero de póliza"
          key="numberPolicy"
          dataIndex="numberPolicy"
          className="item-name"
        />
        <Column
          title="ID cotización"
          dataIndex="id"
          key="id"
          className="item"
          width={110}
        />
        <Column
          title="Aseguradora"
          dataIndex="insurance"
          key="insurance"
          className="item"
        />
        <Column
          title="Nombre del agente"
          dataIndex="agentName"
          key="agentName"
          className="item-name"
        />
        <Column
          title="Fecha de la carga"
          key="dateUpload"
          className="item"
          render={(_, record) => {
            return (
              <span>
                {moment(record.dateUpload).locale("es-mx").format("DD-MM-YYYY")}
              </span>
            );
          }}
        />
        <Column
          title="Link del archivo cargado"
          key="linkFile"
          className="item"
          render={(_, record) => {
            return (
              <a
                className="donwload-file"
                target="_blank"
                href={`${record.linkFile}`}
                rel="noopener noreferrer"
                download={`${record.numberPolicy}`}
              >
                Descargar <img src={donwloadSvg} alt="donwload" />
              </a>
            );
          }}
        />
      </Table>
      {listQuotesLoading && <ModalLoader />}

      {listQuotes?.meta?.totalPages >= 1 && (
        <Pagination
          defaultCurrent={pageQuotes}
          total={listQuotes?.meta?.totalItems}
          pageSize={20}
          onChange={onChange}
        />
      )}
    </div>
  );
};
ListQuotes.propTypes = {
  pageQuotes: number.isRequired,
  onChangePage: func.isRequired,
};
export default ListQuotes;
