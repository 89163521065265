import React from "react";
import { Tabs } from "antd";
import ListInvoices from "./Tabs/ListInvoices";
import PiaPay from "./Tabs/PiaPay";

const { TabPane } = Tabs;

const BillingList = () => {
  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">Listado de facturacion</h1>
      </div>

      <Tabs defaultActiveKey="pay">
        <TabPane tab="Facturas pendientes" key="pay">
          <PiaPay />
        </TabPane>
        <TabPane tab="Facturas pagadas" key="Active">
          <ListInvoices />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default BillingList;
