import React from "react";
import { Upload, message, Button, Input, Switch } from "antd";
import picture from "../../../../../assets/images/picture.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const TabsClaimCoverages = () => {
	const props = {
		name: 'file',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};
	const onChange = (checked) => {
		console.log(`switch to ${checked}`);
	}
	return (
		<div className="TabsBase TabsClaimCoverages">
			<div className="content">
				<div className="content__Layout">
					<h4>Layout</h4>
					<div className="Layout">
						<div className="Layout__card">
							<h6>Coberturas del seguro</h6>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						</div>
					</div>
				</div>
				<div className="content__Layout right">
					<div className="content__Layout--head">
						<div className="cont-here">
							<div className="head-title">
								<h2>Hero</h2>
								<div className="switch">
									<Switch defaultChecked onChange={onChange} />
									<span>Visible</span>
								</div>
							</div>
							<div className="form-covertage">
								<div className="upload">
									<h4>Imagen</h4>
									<Upload className="upload" {...props}>
										<Button><img src={picture} alt="Upload" /><span>Subir imagen</span></Button>
									</Upload>
								</div>
								<div className="textArea">
									<h4>Descripción</h4>
									<TextArea placeholder="Nisl enim, nisi, pharetra fames blandit sed. Pharetra egestas habitasse urna imperdiet gravida facilisi commodo. Quam eget habitasse vestibulum bibendum mus scelerisque." rows={4} />
								</div>
							</div>
						</div>
					</div>
					<div className="textArea">
						<h4>Claim 1 <ExclamationCircleOutlined /></h4>
						<TextArea placeholder="Nisl enim, nisi, pharetra fames blandit sed. Pharetra egestas habitasse urna imperdiet gravida facilisi commodo." rows={4} />
					</div>
					<div className="textArea">
						<h4>Claim 2 <ExclamationCircleOutlined /></h4>
						<TextArea placeholder="Nisl enim, nisi, pharetra fames blandit sed. Pharetra egestas habitasse urna imperdiet gravida facilisi commodo." rows={4} />
					</div>
				</div>
			</div>
		</div>
	)
}