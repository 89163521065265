/* eslint-disable react/require-default-props */
import { Tabs } from "antd";
import { bool, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenovationsAction from "services/renovations/actions";
import CardItemListRenovations from "../CardItemListRenovations/CardItemListRenovations";

const { TabPane } = Tabs;

const TabsListRenovations = ({ policies, isOpenFilters }) => {
  const dispatch = useDispatch();
  const renovations = useSelector((state) => state.renovations);
  const [listFavoritePolicies, setListFavoritePolicies] = useState(
    renovations?.favoritiesArrar
  );

  useEffect(() => {
    dispatch(RenovationsAction.setFavorities(listFavoritePolicies));
  }, [listFavoritePolicies, dispatch]);

  useEffect(() => {
    dispatch(RenovationsAction.getQuotes());
  }, [dispatch]);

  return (
    <section
      className={`TabsListRenovations ${
        isOpenFilters && "TabsListRenovations-z-1"
      }`}
    >
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <div className="title">
              Ver todas <span>({policies?.listPolicies?.length})</span>
            </div>
          }
          key="1"
        >
          {policies?.listPolicies?.length >= 1 ? (
            policies?.listPolicies?.map((item) => (
              <CardItemListRenovations
                key={`key-card--${item?.IdentifierPolicy}`}
                policy={item}
                setListFavoritePolicies={setListFavoritePolicies}
                listFavoritePolicies={listFavoritePolicies}
              />
            ))
          ) : (
            <div className="empty">
              <h2>No hay polizas para mostrar</h2>
            </div>
          )}
        </TabPane>
        <TabPane
          tab={
            <div className="title">
              Filtradas <span>({policies?.policies?.length})</span>
            </div>
          }
          key="2"
        >
          {policies?.policies?.length >= 1 ? (
            policies?.policies?.map((item) => (
              <CardItemListRenovations
                key={`key-card--${item?.IdentifierPolicy}`}
                policy={item}
                setListFavoritePolicies={setListFavoritePolicies}
                listFavoritePolicies={listFavoritePolicies}
              />
            ))
          ) : (
            <div className="empty">
              <h2>No hay polizas para mostrar</h2>
            </div>
          )}
        </TabPane>
        <TabPane
          tab={
            <div className="title">
              Seleccionadas <span>({listFavoritePolicies?.length})</span>
            </div>
          }
          key="3"
        >
          {listFavoritePolicies?.length >= 1 ? (
            listFavoritePolicies?.map((item) => (
              <CardItemListRenovations
                key={`key-card--${item?.IdentifierPolicy}`}
                policy={item}
                setListFavoritePolicies={setListFavoritePolicies}
                listFavoritePolicies={listFavoritePolicies}
              />
            ))
          ) : (
            <div className="empty">
              <h2>No hay polizas para mostrar</h2>
            </div>
          )}
        </TabPane>
        <TabPane
          tab={
            <div className="title">
              Cotizadas{" "}
              <span>({renovations?.success?.listQuotes?.length})</span>
            </div>
          }
          key="4"
        >
          {renovations?.success?.listQuotes?.length >= 1 ? (
            renovations?.success?.listQuotes?.map((item) => (
              <CardItemListRenovations
                key={`key-card--${item?.IdentifierPolicy}`}
                policy={item}
                setListFavoritePolicies={setListFavoritePolicies}
                listFavoritePolicies={listFavoritePolicies}
              />
            ))
          ) : (
            <div className="empty">
              <h2>No hay polizas para mostrar</h2>
            </div>
          )}
        </TabPane>
      </Tabs>
    </section>
  );
};

TabsListRenovations.propTypes = {
  policies: instanceOf(Object),
  isOpenFilters: bool,
};

TabsListRenovations.defaultProps = {
  policies: undefined,
};

export default TabsListRenovations;
