/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import ModalLoader from "components/loader/ModalLoader";
import insuransersActions from "services/insuransers/actions";
import searchSvg from "assets/images/Search-purple.svg";
import closeSvg from "assets/images/close.svg";
import ModalCreateEditInsuranse from "./components/Modals/ModalCreateEditInsuranse/ModalCreateEditInsuranse";
import ModalDelete from "./components/Modals/ModalDelete/ModalDelete";
import ModalRecharge from "./components/Modals/ModalRecharge/ModalRecharge";

const Insuransers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    insuransers,
    rechargeBudget,
    updateInsuransers,
    deleteInsurance,
    createInsurance,
  } = useSelector((state) => state.insuransers.success);
  const { insuransers: insuransersLoading } = useSelector(
    (state) => state.insuransers.loading
  );
  const [objectSearch, setObjectSearch] = useState(undefined);
  const [isListInsuransers, serListInsuransers] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const newList = [];
    for (let index = 0; index < insuransers?.items?.length; index++) {
      const element = insuransers?.items[index];
      newList.push({
        key: element?.id,
        name: element?.customInsuranceCarrier?.insuranceCarrier?.name,
        image: element?.customInsuranceCarrier?.insuranceCarrier?.logoColor,
        insuranceKey: element?.customInsuranceCarrier?.insuranceCarrier?.key,
        emailAdmin: element?.email,
        nameAdmin: element?.person?.name,
        budgets: element?.customInsuranceCarrier?.insuranceCarrier?.budgets,
      });
    }
    serListInsuransers(newList);
  }, [insuransers]);

  useEffect(() => {
    dispatch(insuransersActions.getInsuransers({ page }));
  }, [
    dispatch,
    page,
    rechargeBudget,
    deleteInsurance,
    updateInsuransers,
    createInsurance,
  ]);
  useEffect(() => {
    dispatch(insuransersActions.getAllInsuransers());
  }, [dispatch, deleteInsurance]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleWriting = (e) => {
    setObjectSearch(e.target.value);
  };

  const handleSearch = (name) => {
    const values = { page, name };
    dispatch(insuransersActions.getInsuransers(values));
  };

  const handleClear = () => {
    setObjectSearch(undefined);
    const values = { page };
    dispatch(insuransersActions.getInsuransers(values));
  };

  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">{t("label.insuransersTitle")}</h1>
        <div className="filters">
          <Input
            placeholder="Busca por nombre de aseguradora"
            className="search-line"
            value={objectSearch}
            onChange={(e) => handleWriting(e)}
            suffix={
              <>
                {objectSearch !== "" && (
                  <Button onClick={() => handleClear()}>
                    <img
                      src={closeSvg}
                      className="site-form-item-icon"
                      alt="searcg"
                    />
                  </Button>
                )}
                <Button onClick={() => handleSearch(objectSearch)}>
                  <img
                    src={searchSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              </>
            }
          />

          <ModalCreateEditInsuranse title="create" insuranseKey="" />
        </div>
      </div>
      <div className="insuransers_table">
        {insuransersLoading && <ModalLoader />}
        <Table sticky dataSource={isListInsuransers}>
          <Column
            width={90}
            title="Logo"
            key="logo"
            render={(_, record) => {
              return (
                <img
                  src={record?.image}
                  alt="logo"
                  className="icon-insurance"
                />
              );
            }}
          />
          <Column
            title="Nombre"
            dataIndex="name"
            key="name"
            className="item-name"
            width={120}
          />
          <Column
            title="Email Admin"
            dataIndex="emailAdmin"
            className="item"
            key="emailAdmin"
          />
          <Column
            width={130}
            title="Saldo"
            key="budgets"
            className="item"
            render={(_, record) => {
              return (
                <>
                  {record?.budgets?.length >= 1 ? (
                    <span>${Math.trunc(record?.budgets[0].subTotal)}USD</span>
                  ) : (
                    <span>$0</span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Accines"
            key="id"
            render={(_, record) => {
              return (
                <Space>
                  <ModalRecharge insuranceKey={record} />
                  <ModalCreateEditInsuranse
                    title="edit"
                    insuranceKey={record}
                  />
                  <ModalDelete insuranceKey={record} />
                </Space>
              );
            }}
          />
        </Table>
        {insuransers?.meta?.totalPages >= 1 && (
          <Pagination
            defaultCurrent={page}
            total={insuransers?.meta?.totalItems}
            pageSize={10}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

export default Insuransers;
