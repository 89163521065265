import React, { useState } from "react";
import { LinesOfProduct } from "./components/LinesOfProduct/LinesOfProduct";
import { NewLine } from "./components/NewLine/NewLine";
import berryautopack from "../../../assets/images/berryautopack.jpg";
import { NewProduct } from "./components/NewProduct/NewProduct";

const ProductLine = () => {
  const [viewSelected, setViewSelected] = useState("LinesOfProduct");
  const dummyProducts = [
    {
      id: 1,
      title: "Berry Auto Pack",
      description:
        "Felis at blandit sed semper. Ullamcorper velit imperdiet quis ullamcorper nisl lectus facilisis erat maecenas. Blandit in vitae commodo tellus volutpat sed. Purus in scelerisque arcu neque sed ullamcorper velit imperdiet.",
      img: berryautopack,
      dataSource: [
        {
          key: "1",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
        {
          key: "2",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
      ],
    },
    {
      id: 2,
      title: "Berry Auto Pack",
      description:
        "Felis at blandit sed semper. Ullamcorper velit imperdiet quis ullamcorper nisl lectus facilisis erat maecenas. Blandit in vitae commodo tellus volutpat sed. Purus in scelerisque arcu neque sed ullamcorper velit imperdiet.",
      img: berryautopack,
      dataSource: [
        {
          key: "1",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
        {
          key: "2",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
      ],
    },
    {
      id: 3,
      title: "Berry Auto Pack",
      description:
        "Felis at blandit sed semper. Ullamcorper velit imperdiet quis ullamcorper nisl lectus facilisis erat maecenas. Blandit in vitae commodo tellus volutpat sed. Purus in scelerisque arcu neque sed ullamcorper velit imperdiet.",
      img: berryautopack,
      dataSource: [
        {
          key: "1",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
        {
          key: "2",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
      ],
    },
    {
      id: 4,
      title: "Berry Auto Pack",
      description:
        "Felis at blandit sed semper. Ullamcorper velit imperdiet quis ullamcorper nisl lectus facilisis erat maecenas. Blandit in vitae commodo tellus volutpat sed. Purus in scelerisque arcu neque sed ullamcorper velit imperdiet.",
      img: berryautopack,
      dataSource: [
        {
          key: "1",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
        {
          key: "2",
          name: "A aliquam massa sed.",
          age: "Lorem",
          address: "Lorem",
          link: "https://berrysafe.com/lorem/ipsum.....",
        },
      ],
    },
  ];
  return (
    <div className="ProductLine">
      <div className="Home__container">
        {viewSelected === "LinesOfProduct" && (
          <LinesOfProduct
            setViewSelected={setViewSelected}
            dummyProducts={dummyProducts}
          />
        )}
        {viewSelected === "NewLine" && (
          <NewLine setViewSelected={setViewSelected} />
        )}
        {viewSelected === "NewProduct" && (
          <NewProduct setViewSelected={setViewSelected} />
        )}
      </div>
    </div>
  );
};

export default ProductLine;
