/* eslint-disable import/no-cycle */
import { put, takeLatest, all } from "redux-saga/effects";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import { AGENT_URL } from "../../common/config/config";
import { agents as authActions } from "./actions";

function* getAgents({ payload }) {
  const url = "/agent/get-agents";
  const response = yield Api.post(url, payload);
  if (response.status === "SUCCESS") {
    response.payload.agents = response.payload.agents.map((agent) => ({
      ...agent,
      key: agent.id,
    }));
    return yield put(
      authActions.getAgentsResponse({
        agents: {
          [payload.publishState]: response.payload.agents,
          total: response.payload.total,
        },
      })
    );
  }

  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );

  return yield put(authActions.getAgentsResponse(err, response));
}

function* changePublishPermission({ payload }) {
  const url = "/agent/change-publish";
  const response = yield Api.post(url, payload);

  if (response.status === "SUCCESS") {
    return yield put(authActions.changePublishPermissionResponse({}));
  }
  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );
  return yield put(authActions.changePublishPermissionResponse(err, response));
}

function* loginAsAgent({ payload }) {
  const url = "/auth/superadmin-agent-login";
  const response = yield Api.post(url, { email: payload.user.email });
  if (response.success) {
    window.open(`${AGENT_URL}/sso/${response.detail}`);
    return yield put(authActions.loginAsAgentResponse({}));
  }

  return yield put(authActions.loginAsAgentResponse(parseError(response)));
}

function* findByName({ payload }) {
  const url = "/agent/find-name";
  const response = yield Api.get(url, { agent: payload });
  if (response.success) {
    return yield put(authActions.findByNameResponse(response.detail));
  }

  return yield put(authActions.findByNameResponse(parseError(response)));
}

function* ActionWatcher() {
  yield takeLatest(authActions.getAgents, getAgents);
  yield takeLatest(authActions.loginAsAgent, loginAsAgent);
  yield takeLatest(authActions.findByName, findByName);
  yield takeLatest(
    authActions.changePublishPermission,
    changePublishPermission
  );
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
