import { handleActions } from 'redux-actions';

export const INITIAL_STATE = {
  routes: [],
  actualPosition: undefined,
  error: undefined
}

const reducer = handleActions({
  ROUTES: {
    GET_ROUTES: (state) => ({
      ...state
    }),
    GET_ROUTES_RESPONSE: {
      next(state, { payload: { routes } }) {
        return {
          ...state,
          routes
        }
      },
      throw(state, { payload: { message } }) {
        return {
          ...state,
          error: message,
        }
      }
    },

    SET_ACTUAL_ROUTE: (state, { payload: { actualPosition } }) => ({
      ...state,
      actualPosition
    }),
  }
},
  INITIAL_STATE
);

export default reducer;