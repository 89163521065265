/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { all, put, takeLatest } from "redux-saga/effects";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import LeadActions from "./actions";

function* getLeads({ payload }) {
  const url = "/lead/list-my-leads-admin";
  const response = yield Api.post(url, {
    ...payload,
    quantity: `${payload.quantity}`,
    page: `${payload.page}`,
  });
  if (response.success) {
    response.detail.leads = response.detail.leads.map((lead) => ({
      ...lead,
      key: lead.id,
    }));
    return yield put(
      LeadActions.getLeadsResponse({
        leads: {
          [payload.typeList]: response.detail.leads.filter(
            (lead) => lead.preassignedAgent
          ),
          total: response.detail.total,
        },
      })
    );
  }
  const errorFormatted = parseError(response);
  return yield put(LeadActions.getLeadsResponse(errorFormatted));
}

function* assignAgent({ payload }) {
  const url = "/lead/assign-agent";
  const response = yield Api.post(url, payload);
  if (response.success) return yield put(LeadActions.assignAgentResponse());

  const errorFormatted = parseError(response);
  return yield put(LeadActions.assignAgentResponse(errorFormatted));
}

function* ActionWatcher() {
  yield takeLatest(LeadActions.getLeads, getLeads);
  yield takeLatest(LeadActions.assignAgent, assignAgent);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
