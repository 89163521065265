import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import RenovationsActions from "services/renovations/actions";
import Loader from "../../../components/loader";
import CardItemListRenovations from "../Renovations/components/CardItemListRenovations/CardItemListRenovations";
import CardPdf from "./components/CardPdf/CardPdf";
import CardRisk from "./components/CardRisk/CardRisk";

const DetailPolicy = () => {
  const [listFavoritePolicies, setListFavoritePolicies] = useState([]);
  const [isCardPdfVisible, setCardPdfVisible] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const policy = useSelector((state) => state.renovations);

  useEffect(() => {
    dispatch(RenovationsActions.getPolicy(id));
  }, [id, dispatch]);

  return (
    <section className="DetailPolicy">
      <div className="DetailPolicy__head">
        <h2 className="h2">Detalle de póliza</h2>
        <Button
          className="button button-secundary"
          onClick={() => window.open(policy?.success?.cover)}
        >
          {policy?.loading?.cover ? <Loader /> : "Descargar caratula"}
        </Button>
      </div>
      <div className="buttons-head">
        <Button
          className="button button-primary"
          onClick={() => window.open(policy?.success?.cover)}
        >
          {policy?.loading?.cover ? <Loader /> : "Descargar caratula"}
        </Button>
        <Button
          className="button button-secundary"
          onClick={() => setCardPdfVisible(!isCardPdfVisible)}
        >
          Subir cotización
        </Button>
      </div>
      <div className="DetailPolicy__container">
        <div className="content">
          {policy?.success?.policy?.General?.map((item) => (
            <CardItemListRenovations
              policy={item}
              key={item.AgentKey}
              setListFavoritePolicies={setListFavoritePolicies}
              listFavoritePolicies={listFavoritePolicies}
            />
          ))}
          <div className="card-codes">
            <h3 className="body-regular">
              Fecha de vencimiento: <span>00/00/0000</span>
            </h3>
            <h3 className="body-regular">
              Código postal: <span>101010</span>
            </h3>
          </div>
          <CardRisk />
        </div>
        <div className="card-des">
          <CardPdf setCardPdfVisible={setCardPdfVisible} />
        </div>
        {isCardPdfVisible && (
          <div className="card-float animate__animated animate__fadeIn">
            <CardPdf setCardPdfVisible={setCardPdfVisible} />
          </div>
        )}
      </div>
    </section>
  );
};

export default DetailPolicy;
