import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LayoutPrivate from "../../components/layout/private";
import ProductLine from "./ProductLine/ProductLine";
import AdminUsers from "./adminUsers";
import Agents from "./agents/Agents";
import Home from "./home/Home";
import Leads from "./leads/Leads";
import ProductPercentAssign from "./ProductPercentAssign/ProductPercentAssign";
import Renovations from "./Renovations/Renovations";
import Insuransers from "./Insuransers/Insuransers";
import Quotes from "./Quotes/Quotes";
import AdminAgentOffers from "./AdminAgentOffers/AdminAgentOffers";
import DetailPolicy from "./DetailPolicy/DetailPolicy";
import OffersSent from "./OffersSent/OffersSent";
import OffersAboutExpire from "./OffersAboutExpire/OffersAboutExpire";
import BillingList from "./BillingList/BillingList";
import QuotesReports from "./QuotesReports/QuotesReports";

const Private = () => {
  return (
    <Router>
      <LayoutPrivate>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/users/agents" component={Agents} />
          <Route path="/users/agents/:agentKey" component={Agents} />
          <Route path="/users/admin" component={AdminUsers} />
          <Route path="/leads/listado" component={Leads} />
          <Route path="/product-line" component={ProductLine} />
          <Route path="/billing-list/billing-list" component={BillingList} />
          <Route path="/pia/renovations" exact component={Renovations} />
          <Route path="/insuransers/list" exact component={Insuransers} />
          <Route path="/quotes/quotes" exact component={Quotes} />
          <Route path="/reports/reports" exact component={QuotesReports} />
          <Route
            path="/admin-agent-offers/admin-agent-offers"
            exact
            component={AdminAgentOffers}
          />
          <Route
            path="/offers-about-expire/offers-about-expire"
            exact
            component={OffersAboutExpire}
          />
          <Route path="/offers/offers" exact component={OffersSent} />
          <Route path="/pia/renovations/:id" component={DetailPolicy} />
          <Route
            path="/productos/porcentaje-directos"
            component={ProductPercentAssign}
          />
        </Switch>
      </LayoutPrivate>
    </Router>
  );
};

export default Private;
