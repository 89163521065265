import { Alert, Button, Card, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { auth as AuthActions } from "../../../services/auth/actions";

const useQuery = () => new URLSearchParams(useLocation().search);


const EmailVerification = () => {
  let history = useHistory();
  let query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [verified, setIsVerified] = useState(false)

  const auth = useSelector(state => state.auth)

  useEffect(() => {
    const email = query.get('email');
    if (!verified) {
      dispatch(AuthActions.verifyEmail({
        email
      }))
      setIsVerified(true)
    }

    if (!email)
      history.replace('')


  }, [dispatch, query, history, verified])

  return (
    <div className="change-password">
      <Card className="change-password__card" title={t('label.verifyinEmail')} >
        <Spin spinning={auth.loading.emailVerification} />
        {auth.error && auth.error.emailVerification &&
          <div className="alert">
            <Alert message={t('error.' + auth.error.emailVerification)} type="error" showIcon />
          </div>
        }
        {auth.success && auth.success.emailVerification &&
          <div className="alert">
            <Alert message={t('label.congratEmailActivated')} type="success" showIcon />
            <Button onClick={() => { history.replace('/') }}>Ir al login</Button>
          </div>
        }
      </Card>
    </div>
  )
}

export default EmailVerification