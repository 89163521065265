import React from "react";
import icon1 from "../../../../../assets/images/icon/icon1.png";
import icon2 from "../../../../../assets/images/icon/icon2.png";
import icon3 from "../../../../../assets/images/icon/icon3.png";
import icon4 from "../../../../../assets/images/icon/icon4.png";
import icon5 from "../../../../../assets/images/icon/icon5.png";
import icon6 from "../../../../../assets/images/icon/icon6.png";
import icon7 from "../../../../../assets/images/icon/icon7.png";
import icon8 from "../../../../../assets/images/icon/icon8.png";
import icon9 from "../../../../../assets/images/icon/icon9.png";
import icon10 from "../../../../../assets/images/icon/icon10.png";
import menu from "../../../../../assets/images/menu.png";
import { LinkOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

export const TabsPermits = () => {
  const dummyCards = [
    { id: 1, img: icon1 },
    { id: 2, img: icon2 },
    { id: 3, img: icon3 },
    { id: 4, img: icon4 },
    { id: 5, img: icon5 },
    { id: 6, img: icon6 },
    { id: 7, img: icon7 },
    { id: 8, img: icon8 },
    { id: 9, img: icon9 },
    { id: 10, img: icon10 },
  ];
  return (
    <div className="TabsPermits">
      <div className="TabsPermits__card">
        <div className="head-title">
          <h4>Aseguradoras permitidas</h4>
          <div>
            <SearchOutlined />
            <img src={menu} alt="menu" />
          </div>
        </div>
        <div className="cards">
          {dummyCards?.map((item, index) => (
            <div key={index} className="card">
              <img alt="card" src={item.img} />
            </div>
          ))}
        </div>
      </div>
      <div className="TabsPermits--left">
        <h4>
          Cotizador <LinkOutlined />
        </h4>
        <Input placeholder="Título de botón" />
        <Input placeholder="URL" />
        <h4 className="two-title">Lorem retk</h4>
      </div>
    </div>
  );
};
