import React, { useEffect, useState } from "react";
import { Pagination, Table, Button, Popover, Input } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import offersActions from "services/offers/actions";
import calendarSvg from "assets/images/svg-calendar.svg";
import closeSvg from "assets/images/close.svg";
import searchSvg from "assets/images/Search-purple.svg";

import { DayPicker } from "react-day-picker";
import moment from "moment";

const OffersAboutExpire = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [range, setRange] = useState(undefined);
  const [namePerson, setNamePerson] = useState(undefined);
  const { listExpired } = useSelector((state) => state.offers.success);

  useEffect(() => {
    dispatch(offersActions.getExpiredOffers(page));
  }, [dispatch]);

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    if (range !== undefined) {
      const values = {
        page: pageNumber,
        name: namePerson,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getExpiredOffers(values));
    } else {
      const values = { name: namePerson, page: pageNumber };
      dispatch(offersActions.getExpiredOffers(values));
    }
  };

  const onClearRange = () => {
    setRange(undefined);
    const values = { page: 1 };
    dispatch(offersActions.getExpiredOffers(values));
  };

  useEffect(() => {
    if (range?.to !== undefined) {
      setPage(1);
      const values = {
        page: 1,
        name: namePerson,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getExpiredOffers(values));
    }
  }, [range]);

  const handleWriting = (e) => {
    setNamePerson(e.target.value);
  };

  const handleClear = () => {
    setPage(1);
    setNamePerson(undefined);
    if (range !== undefined) {
      const values = {
        page: 1,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getExpiredOffers(values));
    } else {
      const values = { page: 1 };
      dispatch(offersActions.getExpiredOffers(values));
    }
  };

  const handleSearch = (name) => {
    setPage(1);
    setNamePerson(name);
    if (range !== undefined) {
      const values = {
        page: 1,
        name,
        startDate: formatDate(range.from),
        endDate: formatDate(range.to),
      };
      dispatch(offersActions.getExpiredOffers(values));
    } else {
      const values = { page: 1, name };
      dispatch(offersActions.getExpiredOffers(values));
    }
  };

  return (
    <div className="agents insuransers">
      <div className="insuransers-head">
        <h1 className="agents__title">Ofertas próximas a vencer</h1>
        <div className="filters">
          <Input
            placeholder="Busca por nombre de aseguradora"
            className="search-line"
            value={namePerson}
            onChange={(e) => handleWriting(e)}
            suffix={
              <>
                {namePerson !== "" && (
                  <Button onClick={() => handleClear()}>
                    <img
                      src={closeSvg}
                      className="site-form-item-icon"
                      alt="searcg"
                    />
                  </Button>
                )}
                <Button onClick={() => handleSearch(namePerson)}>
                  <img
                    src={searchSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              </>
            }
          />
          <Popover
            trigger="click"
            content={
              <DayPicker
                numberOfMonths={2}
                mode="range"
                selected={range}
                onSelect={setRange}
              />
            }
            title={<h2 className="sub-title">Rango de fechas</h2>}
          >
            <div className="button-calendar">
              <img src={calendarSvg} alt="calendar" className="calendar" />
              {range?.to !== undefined
                ? `${formatDate(range.from)} / ${formatDate(range.to)}`
                : "aaaa-mm-dd / aaaa-mm-dd"}
              {range?.to !== undefined && (
                <Button onClick={() => onClearRange()}>
                  <img
                    src={closeSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              )}
            </div>
          </Popover>
        </div>
      </div>
      <div className="insuransers_table">
        <Table sticky dataSource={listExpired?.items}>
          <Column
            title="ID"
            key="id"
            dataIndex="id"
            className="item"
            width={70}
          />
          <Column
            title="Numero de póliza"
            key="policyNumber"
            dataIndex="policyNumber"
            className="item"
            width={150}
          />
          <Column
            title="Nombre del agente"
            key="nameAgent"
            className="item-name"
            render={(_, record) => {
              return <span>{record.agent?.user.person.name}</span>;
            }}
          />
          <Column
            title="Valor de la oferta"
            key="insuranceAmount"
            className="item"
            render={(_, record) => {
              return <span>${record.insuranceAmount}USD</span>;
            }}
          />
          <Column
            title="Fecha de envio"
            key="dateUpload"
            className="item"
            render={(_, record) => {
              return (
                <span>
                  {moment(record.createdAt)
                    .locale("es-mx")
                    .format("DD/MM/YYYY")}
                </span>
              );
            }}
          />
          <Column
            title="Fecha de vencimiento"
            key="dateUpload"
            className="item"
            render={(_, record) => {
              return (
                <span>
                  {moment(record.expirationDate)
                    .locale("es-mx")
                    .format("DD/MM/YYYY")}
                </span>
              );
            }}
          />
          <Column
            title="Estado"
            key="offerState"
            className="item"
            render={(_, record) => {
              return (
                <>
                  <span>
                    {record.offerState === "quotation" && "Cotización"}
                  </span>
                  <span>{record.offerState === "viewed" && "Vista"}</span>
                  <span>{record.offerState === "sent" && "Enviada"}</span>
                </>
              );
            }}
          />
          <Column
            title="Vencimiento de oferta"
            key="days"
            className="item"
            render={(_, record) => {
              return (
                <span>
                  {record.days <= 0
                    ? "Vencida"
                    : `${record.days} ${record?.days === 1 ? "Día" : "Días"}`}
                </span>
              );
            }}
          />
        </Table>
        <Pagination
          defaultCurrent={page}
          total={listExpired?.meta?.totalItems}
          pageSize={10}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default OffersAboutExpire;
