import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    products: false,
    percentUpdated: false,
  },
  error: {
    products: undefined,
    percentUpdated: false,
  },
  success: {
    products: undefined,
    percentUpdated: false,
  },
};

const reducer = handleActions(
  {
    PRODUCTS: {
      GET_PRODUCTS: (state) => ({
        ...state,
        loading: { ...state.loading, products: true },
        error: { ...state.error, products: undefined },
      }),
      GET_PRODUCTS_RESPONSE: {
        next(state, { payload: products }) {
          return {
            ...state,
            loading: { ...state.loading, products: false },
            success: {
              ...state.success,
              products,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, products: message },
            success: { ...state.success, products: undefined },
            loading: { ...state.loading, products: false },
          };
        },
      },

      GET_PRODUCTS_SPECIALS: (state) => ({
        ...state,
        loading: { ...state.loading, products: true },
        error: { ...state.error, products: undefined },
      }),
      GET_PRODUCTS_SPECIALS_RESPONSE: {
        next(state, { payload: products }) {
          return {
            ...state,
            loading: { ...state.loading, products: false },
            success: {
              ...state.success,
              products,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, products: message },
            success: { ...state.success, products: undefined },
            loading: { ...state.loading, products: false },
          };
        },
      },

      SET_PERCENT: (state) => ({
        ...state,
        success: { ...state.success, percentUpdated: undefined },
        loading: { ...state.loading, percentUpdated: true },
        error: { ...state.error, percentUpdated: undefined },
      }),
      SET_PERCENT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, percentUpdated: false },
            success: {
              ...state.success,
              percentUpdated: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, percentUpdated: message },
            success: { ...state.success, percentUpdated: undefined },
            loading: { ...state.loading, percentUpdated: false },
          };
        },
      },
      CLEAR_SET_PERCENT: (state) => ({
        ...state,
        loading: { ...state.loading, percentUpdated: false },
        error: { ...state.error, percentUpdated: false },
        success: { ...state.success, percentUpdated: false },
      }),
    },
  },
  INITIAL_STATE
);

export default reducer;
