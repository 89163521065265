export const ENV = process.env.ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const AGENT_URL = process.env.REACT_APP_AGENT_URL;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const DATABASE_URL = process.env.REACT_APP_DATABASE_URL;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
export const API_PIA_URL =
  process.env.REACT_APP_API_PIA_URL || "https://stg-pia-api.berrysafe.com/";
export const isProduction = process.env.ENV === "production";
export const FILE_VIEWER_HOST = process.env.REACT_APP_FILE_VIEWER_HOST;
