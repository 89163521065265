import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    leads: false,
    assignAgent: false,
  },
  error: {
    leads: undefined,
    assignAgent: false,
  },
  success: {
    leads: { toBeConfirmed: [], process: [], total: 0 },
    assignAgent: false,
  },
};

const reducer = handleActions(
  {
    LEADS: {
      GET_LEADS: (state) => ({
        ...state,
        success: { ...state.success, leads: INITIAL_STATE.success.leads },
        loading: { ...state.loading, leads: true },
        error: { ...state.error, leads: undefined },
      }),
      GET_LEADS_RESPONSE: {
        next(state, { payload: { leads } }) {
          return {
            ...state,
            loading: { ...state.loading, leads: false },
            success: {
              ...state.success,
              leads: { ...state.success.leads, ...leads },
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, leads: message },
            success: { ...state.success, leads: INITIAL_STATE.success.leads },
            loading: { ...state.loading, leads: false },
          };
        },
      },

      ASSIGN_AGENT: (state) => ({
        ...state,
        success: { ...state.success, assignAgent: false },
        loading: { ...state.loading, assignAgent: true },
        error: { ...state.error, assignAgent: undefined },
      }),
      ASSIGN_AGENT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, assignAgent: false },
            success: {
              ...state.success,
              assignAgent: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, assignAgent: message },
            success: {
              ...state.success,
              assignAgent: false,
            },
            loading: { ...state.loading, assignAgent: false },
          };
        },
      },
      CLEAR_ASSIGN_AGENT: (state) => ({
        ...state,
        success: { ...state.success, assignAgent: false },
      }),

      CHANGE_AGENT: (state, { payload }) => {
        const { leads } = state.success;
        leads.toBeConfirmed = leads.toBeConfirmed.map((lead) => {
          if (lead.id === payload.lead.id)
            return { ...lead, preassignedAgent: payload.agent };
          return lead;
        });
        return {
          ...state,
          success: { ...state.success, leads },
        };
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
