import React from "react";
import { bool, func } from "prop-types";
import { Button } from "antd";
import filterSvg from "../../../../../assets/images/filter.svg";

const HeaderFilters = ({ isOpenFilters, setOpenFilters }) => {
  return (
    <section className="HeaderFilters">
      <div className="HeaderFilters__container">
        <div className="HeaderFilters__container__head">
          <h1 className="h2">Lista de Renovaciones</h1>
          <Button
            className="btn-filter"
            onClick={() => setOpenFilters(!isOpenFilters)}
          >
            Filtros
            <img src={filterSvg} className="filter-img" alt="filter" />
          </Button>
        </div>
      </div>
    </section>
  );
};

HeaderFilters.propTypes = {
  isOpenFilters: bool.isRequired,
  setOpenFilters: func.isRequired,
};

export default HeaderFilters;
