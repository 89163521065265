/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
import { Button } from "antd";
import closeSvg from "assets/images/close-delete.svg";
import likeSvg from "assets/images/like-berrysafe.svg";
import sendSvg from "assets/images/send.svg";
import close2Svg from "assets/images/close-purple.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import RenovationsAction from "services/renovations/actions";
import { func } from "prop-types";

const CardPdf = ({ setCardPdfVisible }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [readFile, setReadFile] = useState(null);
  const [success, setSuccess] = useState(null);

  const onChangePdf = (e) => {
    const { files } = e.target;
    files.length > 0 && setReadFile(files[0]);
  };

  const handleSendPdf = () => {
    if (readFile) {
      dispatch(RenovationsAction.setPolicyPdf(readFile, id));
      setSuccess("OK");
    }
  };

  return (
    <section className="CardPdf">
      <div className="CardPdf__head">
        <h4 className="sub-title">Cotización</h4>
        <Button className="close" onClick={() => setCardPdfVisible(false)}>
          <img src={close2Svg} alt="close" />
        </Button>
      </div>
      {success === "OK" ? (
        <div className="thanks">
          <img src={likeSvg} className="like" alt="like" />
          <h4>
            ¡Cotización enviada con éxito al agente Berrysafe{" "}
            <span>Antonio Aguilar!</span>
          </h4>
        </div>
      ) : (
        <div className="drag">
          {readFile ? (
            <div
              style={{
                height: "80%",
              }}
            >
              <div className="head">
                <Button className="close" onClick={() => setReadFile("")}>
                  <img src={closeSvg} className="close" alt="close" />
                </Button>
              </div>
              <embed
                src={URL.createObjectURL(readFile)}
                type="application/pdf"
                height="100%"
                width="100%"
                className="cont-pdf"
              />
              <Button className="button" onClick={handleSendPdf}>
                Enviar cotización
                <img className="send" alt="sendSvg" src={sendSvg} />
              </Button>
            </div>
          ) : (
            <div className="after-content">
              <p className="after-text">
                Arrastra aquí el archivo .PDF de la cotización o buscalo{" "}
                <span>aquí</span>
              </p>
              <input
                className="upload-pdf"
                type="file"
                accept=".pdf"
                onChange={onChangePdf}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

CardPdf.propTypes = {
  setCardPdfVisible: func.isRequired,
};

export default CardPdf;
