/* eslint-disable import/no-cycle */
import { all, put, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-named-as-default
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import ProductActions from "./actions";

function* getProducts() {
  const url = "/product-line/percent";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(ProductActions.getProductsResponse(response.detail));
  }
  return yield put(ProductActions.getProductsResponse(parseError(response)));
}

function* getProductsSpecials() {
  const url = "/product/all-specials";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(ProductActions.getProductsResponse(response.detail));
  }
  return yield put(ProductActions.getProductsResponse(parseError(response)));
}

function* setPercent({ payload }) {
  const url = "/product/set-direct-percent";
  const response = yield Api.post(url, payload);
  if (response.success) return yield put(ProductActions.setPercentResponse());

  return yield put(ProductActions.setPercentResponse(parseError(response)));
}

function* ActionWatcher() {
  yield takeLatest(ProductActions.getProducts, getProducts);
  yield takeLatest(ProductActions.setPercent, setPercent);
  yield takeLatest(ProductActions.getProductsSpecials, getProductsSpecials);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
