import { put, takeLatest, all } from "redux-saga/effects";
import { user as userActions } from "./actions";
import Api from "../../common/api/Api";

// const users = [
//   { "id": "10", "email": "daniel.solano@arepa.co", "createdAt": "2020-07-23T10:09:07.462Z", "roles": [{ "id": "7", "state": "Active", "rol": { "name": "Administrador" } }], "creator": { "id": "10", "person": { "name": "Daniel Puentes" } }, "person": { "name": "Daniel Puentes" } },
//   { "id": "10", "email": "daniel.solano@arepa.co", "createdAt": "2020-07-23T10:09:07.462Z", "roles": [{ "id": "7", "state": "Active", "rol": { "name": "Administrador" } }], "creator": { "id": "10", "person": { "name": "Daniel Puentes" } }, "person": { "name": "Daniel Puentes" } },
//   { "id": "10", "email": "daniel.solano@arepa.co", "createdAt": "2020-07-23T10:09:07.462Z", "roles": [{ "id": "7", "state": "Active", "rol": { "name": "Administrador" } }], "creator": { "id": "10", "person": { "name": "Daniel Puentes" } }, "person": { "name": "Daniel Puentes" } },
// ]

function* getUsers({ payload }) {
  const url = "/user/users";
  const response = yield Api.post(url, payload);

  if (response.status === "SUCCESS") {
    response.payload = response.payload.map((user) => ({
      ...user,
      key: user.id,
    }));

    // return yield put(userActions.getUsersResponse({
    //   users: users.map((user, i) => ({ ...user, key: i }))
    // }));
    return yield put(
      userActions.getUsersResponse({
        users: response.payload,
      })
    );
  }
  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );
  yield put(userActions.getUsersResponse(err, response));
}

function* createUser({ payload: { user } }) {
  const url = "/user/create";
  const response = yield Api.post(url, { ...user, permissionOption: "edit" });
  if (response.status === "SUCCESS") {
    return yield put(userActions.createUserResponse());
  }
  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );
  yield put(userActions.createUserResponse(err, response));
}

function* inactivateUser({ payload: { user } }) {
  const url = "/user/inactivate";
  const response = yield Api.post(url, { id: user });
  if (response.status === "SUCCESS")
    return yield put(userActions.inactivateUserResponse());

  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );
  yield put(userActions.inactivateUserResponse(err, response));
}

function* editUser({ payload: { user } }) {
  const url = "/user/edit";
  const response = yield Api.post(url, user);
  if (response.status === "SUCCESS")
    return yield put(userActions.editUserResponse());

  const err = new TypeError(
    response?.status ? response.message : "UNDEFINED_ERROR"
  );
  yield put(userActions.editUserResponse(err, response));
}

function* ActionWatcher() {
  yield takeLatest(userActions.getUsers, getUsers);
  yield takeLatest(userActions.createUser, createUser);
  yield takeLatest(userActions.inactivateUser, inactivateUser);
  yield takeLatest(userActions.editUser, editUser);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
