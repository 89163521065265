/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import insuransersActions from "services/insuransers/actions";
import { instanceOf } from "prop-types";

const ModalRecharge = ({ insuranceKey }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }
  const onFinish = (values) => {
    const valuesSent = {
      email: insuranceKey.emailAdmin,
      insuranceKey: insuranceKey.insuranceKey,
      budget: values.budget,
    };
    dispatch(insuransersActions.rechargeBudget(valuesSent));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="modal-styles">
      <Button className="button button-primary" onClick={openModal}>
        Recargar
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general"
      >
        <h1 className="body-bold">Recargar Aseguradora {insuranceKey.name}</h1>
        <div className="body">
          <h2 className="h3">
            Saldo actual:
            {insuranceKey?.budgets?.length >= 1 ? (
              <span> ${Math.trunc(insuranceKey?.budgets[0].total)}USD</span>
            ) : (
              <span> $0</span>
            )}
          </h2>
        </div>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="budget"
            rules={[
              {
                required: true,
                message: "Please input your budget!",
              },
            ]}
          >
            <Input
              type="number"
              className="custom-imput"
              placeholder="Cantidad a recargar en dolares"
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="button button-primary">
              Recargar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
ModalRecharge.propTypes = {
  insuranceKey: instanceOf(Object).isRequired,
};
export default ModalRecharge;
