import * as firebase from 'firebase/app'
import "firebase/auth";
import {
  GOOGLE_API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  GOOGLE_APP_ID,
  MEASUREMENT_ID
} from "../config/config";

export const app = firebase.initializeApp({
  apiKey: GOOGLE_API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: GOOGLE_APP_ID,
  measurementId: MEASUREMENT_ID
})