import { Alert, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/loader";
import { auth as AuthActions } from "../../../../../services/auth/actions";
import emailIcon from "../../../../../assets/images/email.png";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const ForgotPassword = ({ cancelForgotPassword, changeToValidateCode }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.success.forgotPassword) {
      changeToValidateCode();
      dispatch(AuthActions.cancelForgotPassword());
    }
  }, [auth.success.forgotPassword, changeToValidateCode, dispatch]);

  const onFinish = (values) => dispatch(AuthActions.forgotPassword(values));

  return (
    <div className="forgotPassword">
      {auth.loading.forgotPassword ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <figure className="forgotPassword__redes">
              <h1 className="forgotPassword__redesTitle">
                {t("label.forgotPasswordTitle")}
              </h1>
              <p className="forgotPassword__redesDescrip">
                {t("label.forgotPasswordDescrip")}
              </p>
            </figure>
          </Row>
          <div className="forgotPassword__form">
            <Form {...layout} name="forgotPassword" onFinish={onFinish}>
              <div className="item">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("error.email"),
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<img src={emailIcon} alt="icon"/>}
                    placeholder={t("placeholder.inputEmail")}
                    className="input-custom"
                  />
                </Form.Item>
              </div>

              <Row>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--secundary"
                    disabled={auth.loading.forgotPassword}
                  >
                    {t("button.continue")}
                  </button>
                </Col>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--white"
                    onClick={cancelForgotPassword}
                  >
                    {t("button.cancel")}
                  </button>
                </Col>
              </Row>
            </Form>

            {auth.error && auth.error.forgotPassword && (
              <div className="alert">
                <Alert
                  message={t("error." + auth.error.forgotPassword)}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
