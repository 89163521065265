/* eslint-disable consistent-return */
import { Breadcrumb, Layout, Menu } from "antd";
import { element } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import IconHome from "../../assets/icon/icon-home-invert.svg";
import Logout from "../../assets/icon/logout.svg";
import MyMenu from "../../common/menu";
import { changeRoute } from "../../common/utils/changeRoute";
import Header from "../header/Header";

const { Sider: SiderAnt, Content } = Layout;

const LayoutPrivate = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const routes = useSelector((state) => state.routes);

  const [collapsedByMobile, setCollapsedByMobile] = useState(false);
  const { width } = window.screen;
  const [collapsed, setCollapsed] = useState(false);
  const [subMenus, setSubMenus] = useState(
    MyMenu.find((menu) => menu.key === routes.actualPosition)
  );
  const [actualSubmenu, setActualSubmenu] = useState(undefined);
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  useEffect(() => {
    const endPoints = history.location.pathname.split("/");
    const actualPath = endPoints[endPoints.length - 1];
    setActualSubmenu(actualPath);

    if (!routes.actualPosition) return;
    const rootPath = routes.actualPosition?.split("/")[0];
    const selectedMenu = MyMenu.find((menu) => menu.key === rootPath);

    if (endPoints?.filter((endPoint) => !!endPoint).length === 0)
      return setBreadcrumbList([selectedMenu?.name]);

    const breads = [
      ...endPoints.map((endPoint) => {
        const isThere = selectedMenu?.items
          .map((item) => item.key)
          .indexOf(endPoint);
        if (selectedMenu?.key === endPoint) return selectedMenu.name;

        if (isThere > -1)
          return selectedMenu?.items.find((sub) => sub.key === endPoint).name;
        return endPoint;
      }),
    ];
    setBreadcrumbList(breads);
  }, [history, routes]);

  useEffect(() => {
    const rootPath = routes.actualPosition?.split("/")[0];
    setSubMenus(MyMenu.find((menu) => menu.key === rootPath?.toLowerCase()));
  }, [routes, history]);

  useEffect(() => {
    if (pathname.includes("/renovations")) {
      setCollapsed(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (!collapsedByMobile) setCollapsedByMobile(true);

    if (width <= 768 && !collapsed && !collapsedByMobile) {
      setCollapsed(true);
    }
  }, [width, collapsed, collapsedByMobile]);

  return (
    <div
      className={`
      ${pathname.includes("/renovations") && "mar-0"}
    `}
    >
      <Header />
      <Breadcrumb className="breadcrumb">
        {breadcrumbList.map((bread) => (
          <Breadcrumb.Item key={bread}>{bread}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Layout className="section-dashboard">
        <div
          className={`section-dashboard__close-sidebar ${
            collapsed === true ? "section-dashboard__close-sidebarClose" : ""
          }`}
        >
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              setCollapsed(!collapsed);
            }}
          >
            <figure>
              <img src={IconHome} alt="" />
              <span
                role="link"
                className={`mike${
                  setCollapsed === true
                    ? "section-dashboard__close-sidebarClose"
                    : "mike"
                }`}
              >
                Home
              </span>
            </figure>
          </a>
        </div>
        <SiderAnt
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="sidebar"
        >
          <div className="sidebar__head">
            <div className="sidebar__head__state">
              <span className="sidebar__head__state__circle" />
              En linea
            </div>
          </div>

          {subMenus && (
            <Menu
              mode="inline"
              className="sidebar__menu"
              selectedKeys={actualSubmenu}
            >
              {subMenus.items.map((submenu) => (
                <Menu.Item
                  key={submenu.key}
                  className="sidebar__menuItem"
                  onClick={() => {
                    changeRoute(submenu.path, history, dispatch);
                  }}
                >
                  {submenu.name}
                </Menu.Item>
              ))}
            </Menu>
          )}

          <a href="./" className="sidebar__logout" type="link">
            <img src={Logout} alt="" />
            Cerrar sesión
          </a>
        </SiderAnt>
        <Layout className="site-layout">
          <Content
            className="site-layout-background"
            style={{
              margin: "0 0 0 30px",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

LayoutPrivate.propTypes = {
  children: element,
};

LayoutPrivate.defaultProps = {
  children: null,
};

export default LayoutPrivate;
