import React from "react";
import { ShoppingOutlined } from '@ant-design/icons';
import { Button, Tabs } from "antd";
import { TabsPermits } from "../TabsPermits/TabsPermits";
import { TabsBase } from "../TabsBase/TabsBase";
import { TabsClaimCoverages } from "../TabsClaimCoverages/TabsClaimCoverages";

const { TabPane } = Tabs;

export const NewProduct = ({ setViewSelected }) => {
	return (
		<div className="new-product">
			<div className="head">
				<div className="left">
					<div className="icon"><ShoppingOutlined /></div>
					<h3>Nuevo producto</h3>
				</div>
				<div>
					<Button className="btn--cancel" onClick={() => setViewSelected('LinesOfProduct')}>Cancelar</Button>
					<Button className="btn btn--secondary" onClick={() => setViewSelected('NewProduct')}>Guardar</Button>
				</div>
			</div>
			<div className="content">
				<Tabs defaultActiveKey="1">
					<TabPane tab="Configuraciones" key="1">
						<TabsPermits/>
    			</TabPane>
					<TabPane tab="Hero" key="2">
						<TabsBase/>
    			</TabPane>
					<TabPane tab="Coberturas" key="3">
						<TabsClaimCoverages/>
    			</TabPane>
					<TabPane tab="Estadística" key="4">
						Tab 4
    			</TabPane>
					<TabPane tab="Recomendaciones & Contratación" key="5">
						Tab 5
    			</TabPane>
					<TabPane tab="Exclusiones" key="6">
						Tab 6
    			</TabPane>
					<TabPane tab="FAQs" key="7">
						Tab 7
    			</TabPane>
				</Tabs>
			</div>
		</div>
	);
}