import { createActions } from "redux-actions";

export const { user } = createActions({
  USER: {
    GET_USERS: (page, quantity, order) => ({
      page,
      quantity,
      order,
    }),
    GET_USERS_RESPONSE: (users) => users,

    CREATE_USER: (user) => ({
      user,
    }),
    CREATE_USER_RESPONSE: () => ({}),

    INACTIVATE_USER: (user) => ({ user }),
    INACTIVATE_USER_RESPONSE: () => ({}),

    EDIT_USER: (user) => ({
      user,
    }),
    EDIT_USER_RESPONSE: () => ({}),
  },
});
