import { Alert, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Loader from "../../../../../../../components/loader";
import { auth as AuthActions } from "../../../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const ValidateCode = ({
  urlCode,
  changeToChangePassword,
  cancelForgotPassword,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.success.codeVerification) changeToChangePassword();

    if (
      !auth.error.codeVerification &&
      !auth.loading.codeVerification &&
      !auth.success.codeVerification &&
      urlCode
    ) {
      form.setFieldsValue({
        code: urlCode,
      });
      form.submit();
    }
  }, [auth, changeToChangePassword, urlCode, form]);

  const onFinish = (values) =>
    dispatch(
      AuthActions.verifyCode({
        code: values.code,
      })
    );

  const cancelCodeValidation = () => {
    cancelForgotPassword();
    history.replace("/");
  };

  return (
    <div className="validateCode">
      {auth.loading.codeVerification ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <figure className="validateCode__header">
              <h1 className="validateCode__headerTitle">
                {t("label.validateCodeTitle")}
              </h1>
              <p className="validateCode__headerDescrip">
                {t("label.checkEmailCode")}
              </p>
            </figure>
          </Row>
          <div className="validateCode__form">
            <Form
              {...layout}
              name="validateCode"
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("error.forgetPasswordCode"),
                  },
                ]}
              >
                <Input
                  placeholder={t("placeholder.code")}
                  className="input-custom"
                />
              </Form.Item>
              <Row>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--secundary"
                    disabled={auth.loading.codeVerification}
                  >
                    {t("button.continue")}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <button
                    onClick={cancelCodeValidation}
                    className="btn-custom btn-custom--white"
                  >
                    {t("button.cancel")}
                  </button>
                </Col>
              </Row>
            </Form>
            {auth.error && auth.error.codeVerification && (
              <div className="alert">
                <Alert
                  message={t("error." + auth.error.codeVerification)}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidateCode;
