/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { Button } from "antd";
import { bool, func, instanceOf } from "prop-types";
import closeSvg from "assets/images/close.svg";
import vidaSvg from "assets/images/vidaSvg.svg";
import autosSvg from "assets/images/autosSvg.svg";
import danosSvg from "assets/images/danosSvg.svg";
import fianzasSvg from "assets/images/fianzasSvg.svg";
import saludSvg from "assets/images/saludSvg.svg";
import PolygonSvg from "assets/images/Polygon.svg";
import filterSvg from "assets/images/menu.png";
import RenovationsAction from "services/renovations/actions";

const Filters = ({ isOpenFilters, policies, setOpenFilters }) => {
  const dispatch = useDispatch();
  const [isCovertageActive, setCovertageActive] = useState("");
  const [orderByExpiration, setOrderByExpiration] = useState("");
  const [range, setRange] = useState({});

  const dummyCovertage = [
    { id: 1, title: "amplia", key: "wide" },
    { id: 2, title: "limitada", key: "limited" },
  ];
  const dummyExpiration = [
    { id: 1, title: "up" },
    { id: 2, title: "down" },
  ];
  const dummyRamos = [
    { id: 1, name: "salud", img: saludSvg },
    { id: 2, name: "autos", img: autosSvg },
    { id: 3, name: "fianzas", img: fianzasSvg },
    { id: 4, name: "daños", img: danosSvg },
    { id: 5, name: "vida", img: vidaSvg },
  ];

  useEffect(() => {
    if (range?.to && range?.from) {
      dispatch(
        RenovationsAction.setFilters(
          "Fechas",
          moment(range.from).format("YYYY-MM-DD"),
          moment(range.to).format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(RenovationsAction.setFilters("clean_fecha"));
    }
  }, [range, dispatch]);

  const handleRamosSlected = (item) => {
    dispatch(RenovationsAction.setFilters("Ramos", item.id));
  };

  const handleCovertageSlected = (item) => {
    setCovertageActive(item);
    dispatch(RenovationsAction.setFilters("covertage", item.title));
  };

  const handlePostalCode = (item) => {
    dispatch(RenovationsAction.setFilters("postalCode", item));
  };

  return (
    <section className="Filters animate__animated animate__fadeIn">
      {!isOpenFilters ? (
        <div className="Filters-heads">
          {policies?.filters?.Ramos?.map(
            (item) =>
              dummyRamos?.some((ramo) => ramo.id === item) && (
                <Button
                  className="btn-filter"
                  key={item}
                  onClick={() =>
                    dispatch(RenovationsAction.setFilters("Ramos", item))
                  }
                >
                  {dummyRamos.map((ramo) => (
                    <span key={`ramo-${ramo.id}`}>
                      {ramo.id === item && ramo.name}
                    </span>
                  ))}
                  <img src={closeSvg} className="close" alt="close" />
                </Button>
              )
          )}
          {policies?.filters?.postalCode[0] !== "" && (
            <Button
              className="btn-filter"
              onClick={() =>
                dispatch(RenovationsAction.setFilters("postalCode", ""))
              }
            >
              <span>{policies?.filters?.postalCode}</span>
              <img src={closeSvg} className="close" alt="close" />
            </Button>
          )}
          {range?.from && range?.to && (
            <Button
              className="btn-filter"
              onClick={() => {
                dispatch(RenovationsAction.setFilters("clean_fecha"));
                setRange({});
              }}
            >
              {policies?.filters?.FechaFinal}
              <span className="to"> - </span>
              {policies?.filters?.FechaFinal}
              <img src={closeSvg} className="close" alt="close" />
            </Button>
          )}
          {isCovertageActive !== "" && (
            <Button
              className="btn-filter"
              onClick={() => {
                dispatch(RenovationsAction.setFilters("covertage", ""));
                setCovertageActive("");
              }}
            >
              {isCovertageActive?.title}
              <img src={closeSvg} className="close" alt="close" />
            </Button>
          )}
        </div>
      ) : (
        <div className="container-filter animate__animated animate__fadeIn">
          <div className="section section-calendar">
            <div className="head">
              <h2 className="title">Fechas</h2>
              <Button
                className="button-close"
                onClick={() => setOpenFilters(!isOpenFilters)}
              >
                Filtros
                <img src={filterSvg} className="filter" alt="filter" />
              </Button>
            </div>
            <DayPicker
              numberOfMonths={2}
              mode="range"
              selected={range}
              onSelect={setRange}
            />
          </div>
          <div className="section section-medium">
            <h2 className="title">Ramos</h2>
            <div className="buttons">
              {dummyRamos?.map((item) => (
                <Button
                  key={`btn-ramo-${item.id}`}
                  onClick={() => handleRamosSlected(item)}
                  className={`btn ${
                    policies?.filters?.Ramos?.some((ramo) => ramo === item.id)
                      ? "btn--active"
                      : ""
                  }`}
                >
                  <img src={item.img} className="icon" alt="icon" />
                  {item.name}
                </Button>
              ))}
            </div>
          </div>
          <div className="section">
            <div className="section-head">
              <h2 className="title">Vencimiento:</h2>
              <div className="filters-buttons">
                {dummyExpiration?.map((expiration) => (
                  <Button
                    key={`btn-expiration-${expiration.id}`}
                    className={`btn ${
                      orderByExpiration === expiration.title && "btn--active"
                    }`}
                    onClick={() => setOrderByExpiration(expiration.title)}
                  >
                    <img
                      src={PolygonSvg}
                      className={`img img-${expiration.title}`}
                      alt="polygon"
                    />
                  </Button>
                ))}
              </div>
            </div>
            <div className="item item--covertage">
              <p className="title">Cobertura:</p>
              <div className="btns">
                {dummyCovertage?.map((item) => (
                  <Button
                    onClick={() => handleCovertageSlected(item)}
                    className={`btn ${
                      item.key === isCovertageActive?.key && "btn--active"
                    }`}
                    key={`item-covertage-${item.key}`}
                  >
                    {item.title}
                  </Button>
                ))}
              </div>
            </div>
            <div className="item">
              <p className="title">Radio de Zona:</p>
              <input
                type="number"
                placeholder="Código postal"
                className="inputss"
                onChange={() =>
                  handlePostalCode(document.querySelector(".inputss").value)
                }
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

Filters.propTypes = {
  isOpenFilters: bool.isRequired,
  policies: instanceOf(Object),
  setOpenFilters: func.isRequired,
};

Filters.defaultProps = {
  policies: undefined,
};

export default Filters;
