import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table } from "antd";
import React from "react";
import IconUser from "../../../../assets/icon/icon-user-table.png";
import SortSvg from "../../../../assets/icon/sort_down.svg";
import NoOrderSvg from "../../../../assets/icon/no-order.svg";

const menu = ({ edit, user, inactive }) => (
  <Menu>
    <Menu.Item>
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          inactive(user);
        }}
      >
        Desactivar
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          edit(user);
        }}
      >
        Editar
      </a>
    </Menu.Item>
  </Menu>
);

const columns = ({ openDetail, edit, inactive, sort, sortColumns }) => {
  return [
    {
      title: () => (
        <div>
          <label>Nombre</label>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              sort("name");
            }}
          >
            {sortColumns.name.value === "ASC" && (
              <img alt="Sort" src={SortSvg} />
            )}

            {sortColumns.name.value === "DESC" && (
              <img
                alt="Sort"
                src={SortSvg}
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            )}

            {sortColumns.name.value === undefined && (
              <img alt="Sort" src={NoOrderSvg} />
            )}
          </a>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      // width: 80,
      // fixed: 'left',
      render: (text, record) => (
        <a
          href="./"
          className="table-cell table-cell--name"
          onClick={(e) => {
            e.preventDefault();
            // openDetail()
          }}
        >
          <img src={IconUser} alt="" />
          {record.person.name}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => <label className="">{record.email}</label>,
    },
    {
      title: "Creador",
      width: 150,
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => (
        <p className="table-cell">
          {record.creator &&
            record.creator.person &&
            record.creator.person.name}
        </p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={() => menu({ inactive, edit, user: record })}
          trigger={["click"]}
          overlayClassName="table-dropdown-custom"
          placement="bottomRight"
        >
          <a
            href="./"
            className="custom-trigger-btn"
            onClick={(e) => e.preventDefault()}
          >
            Editar
            <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];
};

const CustomTable = ({
  data,
  openDetail,
  setPage,
  edit,
  inactive,
  sort,
  sortColumns,
}) => {
  return (
    <div>
      <Table
        scroll={{ x: 1300 }}
        sticky
        columns={columns({ openDetail, edit, inactive, sort, sortColumns })}
        dataSource={data}
        pagination={{
          onChange: (page) => setPage(page),
          position: ["bottomCenter"],
        }}
        className="table-agents"
      />
    </div>
  );
};

export default CustomTable;
