/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { Button, Modal, Tabs } from "antd";
import { instanceOf } from "prop-types";
import ListOffersFeedback from "../Tabs/ListOffersFeedback/ListOffersFeedback";

const { TabPane } = Tabs;

const ModalAdmin = ({ adminSelected }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [listOfferEnd, setListOfferEnd] = useState([]);
  const [listOfferSent, setListOfferSent] = useState([]);

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  useEffect(() => {
    const arrayFeedback = [];
    const arraySent = [];
    if (adminSelected?.length >= 1) {
      for (let index = 0; index < adminSelected.length; index++) {
        const element = adminSelected[index];
        if (
          element?.offerState === "feedback" ||
          element?.offerState === "completed" ||
          element?.offerState === "feedback_negative"
        ) {
          arrayFeedback.push(element);
        }
        if (
          element?.offerState === "sent" ||
          element?.offerState === "viwed" ||
          element?.offerState === "quotation"
        ) {
          arraySent.push(element);
        }
      }
      setListOfferEnd(arrayFeedback);
      setListOfferSent(arraySent);
    }
  }, [adminSelected]);

  return (
    <div className="modal-styles">
      <Button className="button button-primary" onClick={openModal}>
        Ver Info
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general"
        width={800}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={`Ofertas recibidas (${listOfferSent?.length})`}
            key="cotizaciones"
          >
            <ListOffersFeedback listOffer={listOfferSent} className="sent" />
          </TabPane>
          <TabPane
            tab={`Feedback realizado (${listOfferEnd?.length})`}
            key="completed"
          >
            <ListOffersFeedback listOffer={listOfferEnd} className="feedback" />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};
ModalAdmin.propTypes = {
  adminSelected: instanceOf(Object).isRequired,
};
export default ModalAdmin;
