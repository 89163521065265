/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input, Pagination, Select, Table } from "antd";
import { bool, func, instanceOf, number } from "prop-types";
import React from "react";
import IconLupa from "../../../../../assets/icon/icon-lupa.svg";
import columns from "./columns";

const { Option } = Select;

const TableToConfirm = ({
  data,
  setPage,
  setQuantity,
  quantity,
  search,
  isSearching,
  setFilter,
  columnOrder,
  sort,
  total,
  assignAgent,
  openDetail,
  isPage
}) => {
  const handleCant = (value) => {
    setQuantity(parseInt(value));
    const currentPage = parseInt(total) / parseInt(value);
    if (isPage > Math.trunc(currentPage)) {
      if (currentPage > Math.trunc(currentPage)) {
        setPage(Math.trunc(currentPage) + 1);
      } else {
        setPage(Math.trunc(currentPage));
      }
    }
  };
  return (
    <div>
      <div className="actions-head">
        <div className="actions-head__select">
          <span className="actions-head__selectLabel">Ver</span>
          <Select
            value={quantity}
            defaultValue="10"
            onChange={(value) => handleCant(value)}
          >
            <Option value="5">5</Option>
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="25">25</Option>
          </Select>
        </div>

        <div className="actions-head__search">
          {isSearching && (
            <Input
              onChange={(e) => setFilter(e.target.value)}
              className="actions-head__searchInput"
              placeholder="Buscar"
            />
          )}
          <a href="./" onClick={(e) => e.preventDefault()}>
            <figure onClick={() => search()}>
              <img src={IconLupa} alt="" />
            </figure>
          </a>
        </div>
      </div>
      <Table
        scroll={{ x: 1300 }}
        sticky
        pagination={false}
        columns={columns(columnOrder, sort, assignAgent, openDetail)}
        dataSource={data}
        className="table-agents"
      />
      <Pagination
        defaultCurrent={1}
        onChange={(page) => setPage(page)}
        total={total}
        pageSize={quantity}
      />
    </div>
  );
};

TableToConfirm.propTypes = {
  data: instanceOf(Array).isRequired,
  setPage: func.isRequired,
  setQuantity: func.isRequired,
  quantity: number.isRequired,
  search: func.isRequired,
  isSearching: bool.isRequired,
  setFilter: func.isRequired,
  columnOrder: instanceOf(Object).isRequired,
  sort: func.isRequired,
  total: number.isRequired,
  assignAgent: func.isRequired,
  openDetail: func.isRequired,
};

export default TableToConfirm;
