import { Button } from "antd";
import axaSvg from "assets/images/axa.svg";
import carSvg from "assets/images/car.svg";
import eyeSvg from "assets/images/eye.svg";
import starSvg from "assets/images/star.svg";
import starLlenoSvg from "assets/images/starLleno.svg";
import { func, instanceOf } from "prop-types";
import React from "react";
import { useHistory } from "react-router";

const CardItemListRenovations = ({
  policy,
  setListFavoritePolicies,
  listFavoritePolicies,
}) => {
  const history = useHistory();

  const handlePolicyId = (id) => {
    history.push(`/pia/renovations/${id}`);
  };

  const handleFavoritePolicy = () => {
    const arrayFavorities = [...listFavoritePolicies];
    const favorite = arrayFavorities.findIndex(
      (favoriteId) => favoriteId.IdentifierPolicy === policy.IdentifierPolicy
    );

    if (favorite > -1) arrayFavorities.splice(favorite, 1);
    else arrayFavorities.push(policy);

    setListFavoritePolicies(arrayFavorities);
  };

  return (
    <section className="CardItemListRenovations">
      <div className="box box-left">
        <div className="image">
          <img
            src={policy?.imgInsurer ? policy?.imgInsurer : axaSvg}
            className="img"
            alt="insurer"
          />
        </div>
        <div className="text">
          <h3>
            {policy?.BenefeciaryName
              ? policy?.BenefeciaryName
              : policy?.AgentName}
          </h3>
          <div className="text-line">
            <img src={carSvg} className="icon" alt="icon" />
            <p>{policy?.Branch}</p>
          </div>
          <div className="text-footer">
            <div className="flex">
              <p className="sub-title">Vence:</p>
              <p className="item item-error">{policy?.DateExpired}</p>
            </div>
            <div className="flex flex-column">
              <p className="sub-title">Cobertura:</p>
              <p className="item"> Amplia</p>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-items">
          <h4>Cotiza con</h4>
          <div className="flex">
            {policy?.suggestedInsurance === "axa" && (
              <div className="item">
                <img src={axaSvg} alt="icon" />
              </div>
            )}
          </div>
        </div>
        <div className="buttons">
          <Button
            className="button button-stars"
            onClick={handleFavoritePolicy}
          >
            <img
              src={
                listFavoritePolicies?.some(
                  (favorite) =>
                    favorite.IdentifierPolicy === policy?.IdentifierPolicy
                )
                  ? starLlenoSvg
                  : starSvg
              }
              className="icon"
              alt="Estrella de póliza"
            />
          </Button>
          <Button
            className="button button-eyes"
            onClick={() => handlePolicyId(policy?.IdentifierPolicy)}
          >
            <img src={eyeSvg} className="icon" alt="Ver póliza" />
          </Button>
        </div>
      </div>
    </section>
  );
};

CardItemListRenovations.propTypes = {
  policy: instanceOf(Object).isRequired,
  listFavoritePolicies: instanceOf(Object).isRequired,
  setListFavoritePolicies: func.isRequired,
};

export default CardItemListRenovations;
