/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import { all, put, select, takeLatest } from "redux-saga/effects";
import { parseError } from "../../common/api/ErrorParser";
import { AGENT_URL } from "../../common/config/config";
import * as TokenStorage from "../../common/storage/Token";
import { auth as authActions } from "./actions";
import Api from "../../common/api/Api";

function* login({ payload: credentials }) {
  const url = credentials.socialNetwork
    ? "/auth/login-social-network"
    : "/auth/login";
  const response = yield Api.post(url, credentials);

  if (!response.success)
    return yield put(authActions.loginResponse(parseError(response), response));

  yield TokenStorage.save(response.detail, `@token${response.message}`);
  if (response.message === "AGENT") {
    window.location.href = `${AGENT_URL}/sso/${response.detail}`;
    return;
  }

  return yield put(
    authActions.loginResponse({
      token: response.detail,
      profile: yield TokenStorage.decodeToken(),
    })
  );
}

function* setToken({ payload: { token } }) {
  const url = "/auth/verify-token";
  const response = yield Api.getValidate(url, token);

  if (!response.success)
    return yield put(authActions.setTokenResponse(parseError(response)));

  yield put(authActions.setTokenResponse());
}

function* logout() {
  yield TokenStorage.remove();
  return yield put(authActions.logoutResponse());
}

function* changeForgotPassword({ payload: password }) {
  const getAuth = (state) => state.auth;
  const { success: auth } = yield select(getAuth);
  const data = {
    password,
    code: auth.codeVerification.code,
  };
  const response = yield Api.post("/auth/forgot-password", data);
  if (response.status === "SUCCESS")
    return yield put(authActions.changeForgotPasswordResponse());

  let err;
  if (response.status) err = new TypeError(response.status);
  else if (response.message) err = new TypeError(response.message[0]);
  else err = new TypeError("UNDEFINED_ERROR");

  yield put(authActions.changeForgotPasswordResponse(err, response));
}

function* isLogged() {
  const isToken = yield TokenStorage.isToken();
  yield put(authActions.setLogged(isToken));
}

function* forgotPassword({ payload: email }) {
  const response = yield Api.post("/auth/request-forgot-password", {
    ...email,
    origin: "ADMIN",
  });

  if (response.status === "SUCCESS")
    return yield put(authActions.forgotPasswordResponse());

  let err;
  if (response.status) err = new TypeError(response.status);
  else if (response.message) err = new TypeError(response.message[0]);
  else err = new TypeError("UNDEFINED_ERROR");

  yield put(authActions.forgotPasswordResponse(err, response));
}

function* verifyEmail({ payload: email }) {
  const response = yield Api.post("/auth/verify-email", email);

  if (response.status === "SUCCESS")
    return yield put(authActions.verifyEmailResponse());
  let err;
  if (response.status) err = new TypeError(response.status);
  else if (response.message) err = new TypeError(response.message[0]);
  else err = new TypeError("ERROR_FORGOT_PASSWORD");

  yield put(authActions.verifyEmailResponse(err, response));
}

function* verifyCode({ payload: code }) {
  const response = yield Api.post("/auth/verify-code", code);
  if (response.status === "SUCCESS")
    return yield put(authActions.verifyCodeResponse(code));

  let err;
  if (response.status) err = new TypeError(response.status);
  else if (response.message) err = new TypeError(response.message[0]);
  else err = new TypeError("ERROR_FORGOT_PASSWORD");

  yield put(authActions.verifyCodeResponse(err, response));
}

function* ActionWatcher() {
  yield takeLatest(authActions.login, login);
  yield takeLatest(authActions.logout, logout);
  yield takeLatest(authActions.forgotPassword, forgotPassword);
  yield takeLatest(authActions.changeForgotPassword, changeForgotPassword);
  yield takeLatest(authActions.isLogged, isLogged);
  yield takeLatest(authActions.verifyEmail, verifyEmail);
  yield takeLatest(authActions.verifyCode, verifyCode);
  yield takeLatest(authActions.setToken, setToken);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
