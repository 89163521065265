/* eslint-disable react/jsx-no-bind */
import { Modal } from "antd";
import React from "react";
import Loader from ".";

const ModalLoader = () => {
  return (
    <div className="modal-loader">
      <Modal visible footer={null} className="ant-modal-loader">
        <Loader />
      </Modal>
    </div>
  );
};

export default ModalLoader;
