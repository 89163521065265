const label = {
  loginTitle: "Inicia sesión",
  createAccountWith: "Crea una cuenta con",
  email: "Correo",
  password: "Contraseña",
  hello: "¡Hola!",
  welcomeSuperAdmin: "Bienvenido Super Admin",
  alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
  or: "o",
  name: "Nombre",
  lastname: "Apellido",
  phone: "Teléfono",
  forgotPasswordText:
    " Ingresa tu correo electrónico y te enviaremos un link para que puedas cambiar tu contraseña",
  newPassword: "Nueva Contraseña",
  onlineStatus: "Estado:",
  editProfile: "Editar mi perfil",
  tips: "Tips",
  leads: "Leads",
  logOut: "Cerrar sesión",
  verifyinEmail: "Verificando email",
  congratEmailActivated: "Email verificado con éxito",
  confirmPassword: "Confirmar contraseña",
  selectLicenseTypes: "Selecciones el tipo de licencia que tiene",
  isAmasfac: "Pertenece a amasfac",
  yes: "Si",
  not: "No",
  insuranceCarriers: "Aseguradoras",
  marketplace: "Marketplace",
  marketplaceAutoPilot: "Encender piloto automático",
  nextStep: "Siguiente",
  congratsOnboarding: "Muchas gracias por completar la información!",
  continueToProfile: "Puedes continuar personalizando tu perfil aquí",
  insuranceLine: "Línea de seguros",
  agentLogin: "No soy super admin",
  checkYourEmail: "Revisa tu correo",
  checkYourEmailParagraph:
    "Hemos enviado la contraseña de acceso a tu correo electrónico, la necesitarás para tu próximo inicio de sesión",
  agentsTitle: "Agentes",
  insuransersTitle: "Aseguradoras",
  leadsTitle: "Leads",
  forgotPasswordWelcome: "Leo cursus aliquam posuere urna leo",
  toBeConfirmed: "Por asignar",
  assign: "Asignados",
  agentsApproved: "Aprobados",
  agentsPending: "Pendientes",
  agentsDenied: "Denegados",
  adminUserListTitle: "Admin",
  forgotPasswordDescrip:
    "Ingresa el correo electrónico que asociaste a tu cuenta ",
  forgotPasswordTitle: "¿Olvidaste tu contraseña?",
  changeForgotPasswordTitle: "Elige una nueva contraseña",
  changeForgotPasswordSubtitle:
    "Crea una contraseña nueva de 8 caracteres como mínimo",
};

const button = {
  goLogin: "Iniciar sesión",
  login: "Iniciar sesión",
  go: "Iniciar sesión",
  goSignUp: "Regístrate",
  register: "Registrarme",
  logout: "Cerrar sesión",
  haveAnAccessCode: "Tienes código de acceso?",
  forgetPassword: "¿Olvidaste tu contraseña?",
  forgotPassword: "Olvidé mi contraseña",
  loginFacebook: "Iniciar sesión con Facebook",
  loginGoogle: "Iniciar sesión con Google",
  loginTwitter: "Iniciar sesión con Twitter",
  signUpFacebook: "Registrate con Facebook",
  signUpGoogle: "Registrate con Google",
  signUpTwitter: "Registrate con Twitter",
  cancel: "Cancelar",
  send: "Enviar",
  update: "Actualizar",
  detail: "Detalle",
  goToProfile: "Continuar a tu perfil",
  goToBerrysafe: "Continuar a Berrysafe",
  addUser: "Agregar",
  editUser: "Editar",
  continue: "Continuar",
};

const placeholder = {
  inputEmail: "Correo",
  inputPhone: "Teléfono",
  inputName: "Nombre",
  inputPass: "Contraseña",
  inputPassNew: "Contraseña Nueva",
};

const error = {
  email: "Por favor ingresa un email válido",
  charactersEmail:
    "El correo electrónico no puede contener más de 200 caracteres",
  password: "Por favor ingresa tu contraseña",
  charactersPassword: "La contraseña debe estar entre 8 y 20 caracteres",
  name: "Por favor ingresa tu nombre",
  charactersName: "El nombre no puede contener más de 50 caracteres",
  lastname: "Por favor ingresa tu apellido",
  charactersLastname: "El apellido no puede contener más de 50 caracteres",
  phone: "Por favor ingresa tu número de teléfono mexicano",
  charactersPhone:
    "El teléfono no puede contener más de 15 caracteres ni menos de 10",
  confirmPassword: "Por favor confirma tu contraseña",
  EMAIL_BAD_FORMAT: "El email es incorrecto",
  USER_NOT_FOUND: "El email especificado no está registrado",
  UNDEFINED_ERROR: "No se pudo establecer conexión con el servidor",
  USER_NOT_EXIST: "No existe ningún usuario asociado a este email",
};

const alert = {
  success: "Registro exitoso",
  success_password: "Contraseña actualizada existosamente",
  signupSuccess: "Registro exitoso",
};

export { label, button, error, alert, placeholder };
