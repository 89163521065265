import { createActions } from "redux-actions";

const { products } = createActions({
  PRODUCTS: {
    GET_PRODUCTS: () => ({}),
    GET_PRODUCTS_RESPONSE: (productList) => productList,

    GET_PRODUCTS_SPECIALS: () => ({}),
    GET_PRODUCTS_SPECIALS_RESPONSE: (productList) => productList,

    SET_PERCENT: (productId, percent) => ({ product: productId, percent }),
    SET_PERCENT_RESPONSE: (newPercent) => newPercent,
    CLEAR_SET_PERCENT: () => ({}),
  },
});

export default products;
