import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { user as UsersActions } from '../../../../../services/users/actions';

const layout = {
  wrapperCol: {
    span: 24,
  },
}

const EditUser = ({ user, close }) => {

  const [form] = Form.useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users)

  useEffect(() => {
    if (user === undefined)
      return
    form.setFieldsValue({
      name: user.person.name,
      email: user.email,
    })
  }, [user, form])


  useEffect(() => {
    if (users.success.edit) {
      message.success({
        content: 'Usuario editado exitosamente',
      })
      close()
    }


    if (users.error.edit)
      message.error({
        content: 'El usuario no se pudo editar',
      })
  }, [users, close])

  const onFinish = (editedUser) => {
    dispatch(UsersActions.editUser({ id: +user.id, ...editedUser }))
  }

  return (
    <div>
      Editar
      <Form form={form} {...layout} name='editUser' onFinish={onFinish}>
        <Form.Item
          name='name'
          rules={[
            {
              required: true,
              message: t('error.name'),
            },
            {
              max: 50,
              message: t('error.charactersName'),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={t('placeholder.inputName')}
            className='input-custom'
          />
        </Form.Item>

        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: t('error.email'),
              type: 'email',
            },
            {
              max: 200,
              message: t('error.charactersEmail'),
            },
          ]}>
          <Input
            prefix={<MailOutlined />}
            placeholder={t('placeholder.inputEmail')}
            className='input-custom'
          />
        </Form.Item>

        <button
          className='btn-custom btn-custom--secundary'
          disabled={users.loading.edit}>
          {users.loading.edit && <Spin />}
          {t('button.editUser')}
        </button>

      </Form>
    </div>

  )

}


export default EditUser;