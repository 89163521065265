import React from "react";
import carDateSvg from "../../../../../assets/images/carDate.svg";
import infoSvg from "../../../../../assets/images/info.svg";

const CardRisk = () => {
  const dummyDateRisk = {
    id: 1,
    placaNumber: "ABC-12-34",
    serialNumber: "1234567890",
    use: "Ruleteo",
    engineNumber: "1234567890",
    vehicleDescription: "hyundai hb20 gravity 2023",
    Service: "Público",
    vehicleKey: "1234ABC",
  };
  return (
    <section className="CardRisk">
      <h3 className="h3">Datos del riesgo</h3>
      <div className="CardRisk__flex">
        <div className="box box--left">
          <img src={carDateSvg} className="icon" alt="icon" />
          <div className="box-item">
            <h6 className="small-detail">Número de Placa</h6>
            <div className="serial">
              <span>{dummyDateRisk?.placaNumber}</span>
            </div>
          </div>
        </div>
        <div className="box box--medium">
          <div className="box-item">
            <h6 className="small-detail">Número de serie</h6>
            <div className="text">
              <span>{dummyDateRisk?.serialNumber}</span>
            </div>
          </div>
          <div className="box-item">
            <h6 className="small-detail">Uso</h6>
            <div className="text">
              <span>{dummyDateRisk?.use}</span>
            </div>
          </div>
          <div className="box-item">
            <h6 className="small-detail">Número de motor</h6>
            <div className="text">
              <span>{dummyDateRisk?.engineNumber}</span>
            </div>
          </div>
        </div>
        <div className="box box--rigth">
          <div className="box-item">
            <h6 className="small-detail">
              <img src={infoSvg} className="icon" alt="icon" />
              Descripción del vehículo
            </h6>
            <div className="text">
              <span>{dummyDateRisk?.vehicleDescription}</span>
            </div>
          </div>
          <div className="box-item">
            <h6 className="small-detail">Servicio</h6>
            <div className="text">
              <span>{dummyDateRisk?.Service}</span>
            </div>
          </div>
          <div className="box-item">
            <h6 className="small-detail">Clave de vehículo</h6>
            <div className="text">
              <span>{dummyDateRisk?.vehicleKey}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardRisk;
