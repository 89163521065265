import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    users: false,
    create: false,
    edit: false,
    inactive: false,
  },
  error: {
    users: undefined,
    create: undefined,
    edit: undefined,
    inactive: undefined,
  },
  success: {
    users: undefined,
    create: undefined,
    edit: undefined,
    inactive: undefined,
  },
};

const reducer = handleActions(
  {
    USER: {
      GET_USERS: (state) => ({
        ...state,
        success: {
          ...state.success,
          inactive: undefined,
          edit: undefined,
          users: undefined,
          create: undefined,
        },
        loading: { ...state.loading, users: true },
        error: { ...state.error, users: undefined },
      }),
      GET_USERS_RESPONSE: {
        next(state, { payload: { users } }) {
          return {
            ...state,
            loading: { ...state.loading, users: false },
            success: {
              ...state.success,
              users,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, users: message },
            success: { ...state.success, users: undefined },
            loading: { ...state.loading, users: false },
          };
        },
      },

      CREATE_USER: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, create: true },
        error: { ...state.error, create: undefined },
      }),
      CREATE_USER_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, create: false },
            success: {
              ...state.success,
              create: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, create: message },
            success: { ...state.success, create: undefined },
            loading: { ...state.loading, create: false },
          };
        },
      },

      INACTIVATE_USER: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, inactive: true },
        error: { ...state.error, inactive: undefined },
      }),
      INACTIVATE_USER_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, inactive: false },
            success: {
              ...state.success,
              inactive: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, inactive: message },
            success: { ...state.success, inactive: undefined },
            loading: { ...state.loading, inactive: false },
          };
        },
      },

      EDIT_USER: (state) => ({
        ...state,
        success: { ...state.success },
        loading: { ...state.loading, edit: true },
        error: { ...state.error, edit: undefined },
      }),
      EDIT_USER_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, edit: false },
            success: {
              ...state.success,
              edit: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, edit: message },
            success: { ...state.success, edit: undefined },
            loading: { ...state.loading, edit: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
