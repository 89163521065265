/* eslint-disable react/jsx-no-bind */
import { Button, Modal } from "antd";
import moment from "moment";
import { instanceOf } from "prop-types";
import React, { useState } from "react";

const ModalDetailFeedback = ({ itemFeedback }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  return (
    <div className="modal-styles">
      <Button className="button button-primary" onClick={openModal}>
        Ver detalle
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general"
      >
        <h1 className="sub-title">Detalle del feedback realizado</h1>
        <div className="table-list">
          <div className="row">
            <p className="col body-regular">Número póliza:</p>
            <p className="col body-bold">{itemFeedback?.policyNumber}</p>
          </div>
          <div className="row">
            <p className="col body-regular">Agente Ganador:</p>
            <p className="col body-bold">{itemFeedback?.agentName}</p>
          </div>
          <div className="row">
            <p className="col body-regular">Fecha de subida:</p>
            <p className="col body-bold">
              {itemFeedback?.allItem?.renovations?.length >= 1 &&
                moment(itemFeedback?.allItem?.renovations[0]?.createdAt)
                  .locale("es-mx")
                  .format("DD/MM/YYYY")}
            </p>
          </div>
          <div className="row">
            <p className="col body-regular">Aseguradora:</p>
            <p className="col body-bold">
              {itemFeedback?.allItem?.renovations?.length >= 1 &&
                itemFeedback?.allItem?.renovations[0]?.insuranceCarrier?.name}
            </p>
          </div>
          {/* <div className="row">
            <p className="col body-regular">Valor:</p>
            <p className="col body-bold">
              {itemFeedback?.amount ? itemFeedback?.amount : "-"}
            </p>
          </div> */}
          <div className="row">
            <p className="col body-regular">Observación:</p>
            <p className="col body-bold">
              {itemFeedback?.allItem?.renovations?.length >= 1 &&
              itemFeedback?.allItem?.renovations[0]?.feedbacks?.length >= 1 &&
              itemFeedback?.allItem?.renovations[0]?.feedbacks[0]
                ?.observation !== ""
                ? itemFeedback?.allItem?.renovations[0]?.feedbacks[0]
                    ?.observation
                : "-"}
            </p>
          </div>
          <div className="row">
            <p className="col body-regular">Tipo de feedback:</p>
            <p className="col body-bold">
              {itemFeedback?.allItem?.renovations?.length >= 1 &&
              itemFeedback?.allItem?.renovations[0]?.feedbacks?.length >= 1 &&
              itemFeedback?.allItem?.renovations[0]?.feedbacks[0]?.typeFeedback
                ?.name !== ""
                ? itemFeedback?.allItem?.renovations[0]?.feedbacks[0]
                    ?.typeFeedback?.name
                : "-"}
            </p>
          </div>
          {itemFeedback?.allItem?.policy !== null && (
            <>
              <div className="row">
                <p className="col body-regular">Modelo:</p>
                <p className="col body-bold">
                  {itemFeedback?.allItem?.policy?.model !== null
                    ? itemFeedback?.allItem?.policy?.model
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col body-regular">Marca:</p>
                <p className="col body-bold">
                  {itemFeedback?.allItem?.policy?.brand !== null
                    ? itemFeedback?.allItem?.policy?.brand
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col body-regular">Sub marca:</p>
                <p className="col body-bold">
                  {itemFeedback?.allItem?.policy?.subBrand !== null
                    ? itemFeedback?.allItem?.policy?.subBrand
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col body-regular">Descripción:</p>
                <p className="col body-bold">
                  {itemFeedback?.allItem?.policy?.description !== null
                    ? itemFeedback?.allItem?.policy?.description
                    : "-"}
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
ModalDetailFeedback.propTypes = {
  itemFeedback: instanceOf(Object).isRequired,
};
export default ModalDetailFeedback;
