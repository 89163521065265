import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../i18n/i18n";
import { auth as AuthActions } from "../services/auth/actions";
import Private from "./Private/Private";
import Public from "./Public/Public";

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    i18n.changeLanguage("es");
    dispatch(AuthActions.isLogged());
  }, [dispatch, i18n]);

  return auth.isLogged ? <Private /> : <Public />;
};

export default App;
