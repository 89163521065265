import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { auth as AuthActions } from "../../../services/auth/actions";
import * as TokenStorage from '../../../common/storage/Token'
import { useCallback } from 'react';


const TokenValidation = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  let { token } = useParams();
  let auth = useSelector(state => state.auth);

  const setLogged = useCallback(async (token) => {
    history.replace('/')
    await TokenStorage.save(token)
    dispatch(AuthActions.setLogged(true))
  }, [dispatch, history])

  useEffect(() => {
    if (
      token &&
      !auth.error.setToken &&
      !auth.loading.setToken
    )
      dispatch(AuthActions.setToken(token))

    if (auth.error.setToken || auth.success.setToken)
      setLogged(token)

  }, [token, auth, dispatch, setLogged])





  return (
    <div>
      Estamos validando tu información
    </div>
  )
}

export default TokenValidation;