import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import offersActions from "services/offers/actions";

import ListQuotes from "./Tabs/ListQuotes";
import ListFeedback from "./Tabs/ListFeedback";

const { TabPane } = Tabs;

const Quotes = () => {
  const dispatch = useDispatch();
  const [pageQuotes, setPageQuotes] = useState(1);

  useEffect(() => {
    const values = { pageQuotes };
    dispatch(offersActions.getQuotes(values));
  }, [dispatch, pageQuotes]);

  const onChangePage = (pageNumber, tableName) => {
    if (tableName === "quotes") {
      setPageQuotes(pageNumber);
    }
  };

  return (
    <div className="agents insuransers">
      <div className="insuransers_table">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Cotizaciones" key="cotizaciones">
            <ListQuotes onChangePage={onChangePage} pageQuotes={pageQuotes} />
          </TabPane>
          <TabPane tab="Feedback" key="feddback">
            <ListFeedback />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Quotes;
