import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { changeRoute } from "../../../common/utils/changeRoute";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    changeRoute("Users/agents", history, dispatch);
  }, [history, dispatch]);
  return <div>Home</div>;
};

export default Home;
