import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import offersActions from "services/offers/actions";
import donwloadSvg from "assets/images/donwload-purple-svg.svg";

import moment from "moment";

const ListInvoices = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { billingList } = useSelector((state) => state.offers.success);

  useEffect(() => {
    dispatch(offersActions.getBillingList(page));
  }, [dispatch]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(offersActions.getBillingList({ page: pageNumber }));
  };
  return (
    <div className="insuransers_table">
      <Table sticky dataSource={billingList?.items}>
        <Column
          title="ID"
          key="id"
          dataIndex="id"
          className="item"
          width={70}
        />
        <Column
          title="Saldo a retirar del agente"
          key="amount"
          className="item"
          render={(_, record) => {
            return <span>${record.amount} USD</span>;
          }}
        />
        <Column
          title="Nombre del agente solicitante"
          key="nameAgent"
          className="item-name"
          render={(_, record) => {
            return <span>{record.agent?.user.person.name}</span>;
          }}
        />
        <Column
          title="Fecha de la solicitud de pago. "
          key="dateUpload"
          className="item"
          render={(_, record) => {
            return (
              <span>
                {moment(record.createdAt).locale("es-mx").format("DD/MM/YYYY")}
              </span>
            );
          }}
        />
        <Column
          title="Archivo"
          key="days"
          className="item"
          render={(_, record) => {
            return (
              <a
                className="donwload-file"
                target="_blank"
                href={`${record.piaFile?.url}`}
                rel="noopener noreferrer"
                download={`${record.agent?.user.person.name}`}
              >
                Descargar <img src={donwloadSvg} alt="donwload" />
              </a>
            );
          }}
        />
      </Table>
      <Pagination
        defaultCurrent={page}
        total={billingList?.meta?.totalItems}
        pageSize={10}
        onChange={onChange}
      />
    </div>
  );
};

export default ListInvoices;
