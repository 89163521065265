// import ProductPercentAssign from "scenes/Private/ProductPercentAssign/ProductPercentAssign";
import AdminAgentOffers from "scenes/Private/AdminAgentOffers/AdminAgentOffers";
import ListSvg from "assets/icon/list.svg";
import AdminUsers from "scenes/Private/adminUsers";
import Agents from "scenes/Private/agents/Agents";
// import Leads from "scenes/Private/leads/Leads";
import Insuransers from "scenes/Private/Insuransers/Insuransers";
import Quotes from "scenes/Private/Quotes/Quotes";
import OffersAboutExpire from "scenes/Private/OffersAboutExpire/OffersAboutExpire";
import BillingList from "scenes/Private/BillingList/BillingList";
import QuotesReports from "scenes/Private/QuotesReports/QuotesReports";

const listItems = [
  {
    name: "Agentes",
    path: "users/agents",
    key: "agents",
    component: Agents,
    default: true,
  },
  {
    name: "Administradores",
    path: "users/admin",
    key: "admin",
    component: AdminUsers,
  },
  {
    name: "Aseguradoras",
    path: "insuransers/list",
    key: "list",
    component: Insuransers,
  },
  {
    name: "Cotizaciones",
    path: "quotes/quotes",
    key: "quotes",
    component: Quotes,
  },
  {
    name: "Ofertas enviadas",
    path: "offers/offers",
    key: "offers",
    component: Quotes,
  },
  {
    name: "Agentes activos",
    path: "admin-agent-offers/admin-agent-offers",
    key: "admin-agent-offers",
    component: AdminAgentOffers,
  },
  {
    name: "Ofertas próximas a vencer",
    path: "offers-about-expire/offers-about-expire",
    key: "offers-about-expire",
    component: OffersAboutExpire,
  },
  {
    name: "Reporte de feedback",
    path: "reports/reports",
    key: "reports",
    component: QuotesReports,
  },
  {
    name: "Listado de facturación",
    path: "billing-list/billing-list",
    key: "billing-list",
    component: BillingList,
  },
];

const menu = [
  {
    name: "Usuarios",
    key: "users",
    icon: ListSvg,
    items: listItems,
  },
  {
    name: "Aseguradoras",
    key: "insuransers",
    items: listItems,
  },
  {
    name: "Home",
    key: "",
    items: [
      {
        name: "",
        path: "",
        key: "agents",
        component: Agents,
        default: true,
      },
    ],
  },
  {
    name: "Leads",
    key: "leads",
    items: listItems,
  },
  {
    name: "Productos",
    key: "productos",
    items: listItems,
  },
  {
    name: "Cotizaciones",
    key: "quotes",
    items: listItems,
  },
  {
    name: "Ofertas enviadas",
    key: "offers",
    items: listItems,
  },
  {
    name: "Administracion de ofertas de agentes",
    key: "admin-agent-offers",
    items: listItems,
  },
  {
    name: "Ofertas próximas a vencer",
    key: "offers-about-expire",
    items: listItems,
  },
  {
    name: "Reporte de feedback",
    key: "reports",
    items: listItems,
  },
  {
    name: "Listado de facturación",
    key: "billing-list",
    items: listItems,
  },
];

export default menu;
