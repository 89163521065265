import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    listBilling: false,
    billingPay: false,
    declinePay: false,
  },
  error: {
    listBilling: false,
    billingPay: false,
    declinePay: false,
  },
  success: {
    listBilling: undefined,
    billingPay: undefined,
    declinePay: undefined,
  },
};

const reducer = handleActions(
  {
    BILLING: {
      GET_ALL_BILLING: (state) => ({
        ...state,
        loading: { ...state.loading, listBilling: true },
        success: { ...state.success, listBilling: undefined },
        error: { ...state.error, listBilling: undefined },
      }),
      GET_ALL_BILLING_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listBilling: false },
            success: {
              ...state.success,
              listBilling: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listBilling: message },
            success: { ...state.success, listBilling: undefined },
            loading: { ...state.loading, listBilling: false },
          };
        },
      },
      BILLING_PAY: (state) => ({
        ...state,
        loading: { ...state.loading, billingPay: true },
        success: { ...state.success, billingPay: undefined },
        error: { ...state.error, billingPay: undefined },
      }),
      BILLING_PAY_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, billingPay: false },
            success: {
              ...state.success,
              billingPay: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, billingPay: message },
            success: { ...state.success, billingPay: undefined },
            loading: { ...state.loading, billingPay: false },
          };
        },
      },
      DECLINE_PAY: (state) => ({
        ...state,
        loading: { ...state.loading, declinePay: true },
        success: { ...state.success, declinePay: undefined },
        error: { ...state.error, declinePay: undefined },
      }),
      DECLINE_PAY_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, declinePay: false },
            success: {
              ...state.success,
              declinePay: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, declinePay: message },
            success: { ...state.success, declinePay: undefined },
            loading: { ...state.loading, declinePay: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
