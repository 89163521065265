import { createActions } from "redux-actions";

const { renovations } = createActions({
  RENOVATIONS: {
    GET_POLICIES: () => ({}),
    GET_POLICIES_RESPONSE: (productList) => productList,

    GET_LIST_POLICIES: () => ({}),
    GET_LIST_POLICIES_RESPONSE: (listPolicies) => listPolicies,

    GET_NEXT_RENOVATIONS: () => ({}),
    GET_NEXT_RENOVATIONS_RESPONSE: (nextRenovations) => ({ nextRenovations }),

    GET_QUOTES: () => ({}),
    GET_QUOTES_RESPONSE: (listQuotes) => ({ listQuotes }),

    GET_COVER: (cover) => ({ cover }),
    GET_COVER_RESPONSE: (cover) => ({ cover }),

    GET_POLICY: (policy) => ({ policy }),
    GET_POLICY_RESPONSE: (policy) => policy,

    GET_ALL_ACCOUNTS: () => ({}),
    GET_ALL_ACCOUNTS_RESPONSE: (accounts, insurances) => ({
      accounts,
      insurances,
    }),
    CLEAN_ALL_ACCOUNTS_RESPONSE: () => ({}),

    SET_FILTERS: (key, value, secondValue) => ({ key, value, secondValue }),

    SET_FAVORITIES: (favorities) => ({ favorities }),
    SET_FAVORITIES_RESPONSE: (favorities) => favorities,

    SET_POLICY_PDF: (file, policyNumber) => ({
      file,
      policyNumber: { policyNumber },
    }),
    SET_POLICY_PDF_RESPONSE: () => ({}),
  },
});

export default renovations;
