import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmAlert = ({
  isVisible,
  onOk,
  onCancel,
  message,
  okClassName,
  cancelClassName,
  onAcceptClick,
  onDeniedClick,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title="Basic Modal"
      className="modal-warnning"
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div className="cont-modal">
        <h3>{message}</h3>
        <p>{description}</p>
        <div className="btns">
          <Button className={okClassName} onClick={onAcceptClick}>
            {t("label.yes")}
          </Button>
          <Button className={cancelClassName} onClick={onDeniedClick}>
            {t("label.not")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmAlert;
