import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenovationsActions from "services/renovations/actions";
import CardRenovations from "./components/CardRenovations/CardRenovations";
import Filters from "./components/Filters/Filters";
import HeaderFilters from "./components/HeaderFilters/HeaderFilters";
import TabsListRenovations from "./components/TabsListRenovations/TabsListRenovations";
import Loader from "../../../components/loader/index";

const Renovations = () => {
  const dispatch = useDispatch();
  const policies = useSelector((state) => state.renovations);
  const [isOpenFilters, setOpenFilters] = useState(false);

  useEffect(() => {
    dispatch(RenovationsActions.getPolicies());
    dispatch(RenovationsActions.getListPolicies());
    dispatch(RenovationsActions.getNextRenovations());
  }, [dispatch]);

  return (
    <section className="Renovations">
      <div className="Renovations__container">
        <HeaderFilters
          isOpenFilters={isOpenFilters}
          setOpenFilters={setOpenFilters}
          policies={policies}
        />
        <div className="content">
          {policies?.loading?.policies &&
          policies?.loading?.listQuotes &&
          policies?.loading?.nextRenovations ? (
            <Loader />
          ) : (
            <>
              <CardRenovations item={policies?.success?.nextRenovations} />
              <div className="content-rth">
                <Filters
                  policies={policies}
                  isOpenFilters={isOpenFilters}
                  setOpenFilters={setOpenFilters}
                />
                <TabsListRenovations
                  policies={policies?.success}
                  isOpenFilters={isOpenFilters}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Renovations;
