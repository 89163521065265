/* eslint-disable react/jsx-no-bind */
import { Button, Modal } from "antd";
import { instanceOf } from "prop-types";
import React, { useState } from "react";
import billingActions from "services/billing/actions";
import { useDispatch } from "react-redux";

const ModalConfirm = ({ itemBilling }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  const onConfirm = (billingId) => {
    dispatch(billingActions.billingPay(`${billingId}`));
    setIsOpen(false);
  };
  const onDecline = (billingId) => {
    dispatch(billingActions.declinePay(`${billingId}`));
    setIsOpen(false);
  };

  return (
    <div className="modal-styles">
      <Button className="button button-primary" onClick={openModal}>
        Confirmar pago
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general modal-general-confirm"
      >
        <h1 className="sub-title">
          Esta seguro que desea pagar ${itemBilling?.amount}USD a{" "}
          {itemBilling?.agentName}
        </h1>
        <div className="flex">
          <Button
            className="button button-primary"
            onClick={() => onConfirm(itemBilling?.allItems?.id)}
          >
            Confirmar
          </Button>
          <Button
            className="button button-delete"
            onClick={() => onDecline(itemBilling?.allItems?.id)}
          >
            Rechazar pago
          </Button>
        </div>
      </Modal>
    </div>
  );
};
ModalConfirm.propTypes = {
  itemBilling: instanceOf(Object).isRequired,
};

export default ModalConfirm;
