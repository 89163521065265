import { createActions } from "redux-actions";

const { billing } = createActions({
  BILLING: {
    GET_ALL_BILLING: (page) => ({ page }),
    GET_ALL_BILLING_RESPONSE: (listBilling) => listBilling,

    BILLING_PAY: (billingId) => ({ billingId }),
    BILLING_PAY_RESPONSE: (billingPay) => billingPay,

    DECLINE_PAY: (billingId) => ({ billingId }),
    DECLINE_PAY_RESPONSE: (billingPay) => billingPay,
  },
});

export default billing;
