/* eslint-disable consistent-return */
const jwtDecode = require("jwt-decode");

const key = "@tokenADMIN";

export const save = (token) => {
  try {
    localStorage.setItem(key, token);
    return { success: "OK" };
  } catch (error) {
    return { error: "ERROR" };
  }
};

export const get = () => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return { error: "ERROR" };
  }
};

export const remove = () => {
  return localStorage.removeItem(key);
};

export const isToken = () => {
  return localStorage.getItem(key) !== null;
};

export const decodeToken = () => {
  const token = localStorage.getItem(key);
  if (token) return jwtDecode(token);
};
