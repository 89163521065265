import { createActions } from "redux-actions";

export const { agents } = createActions({
  AGENTS: {
    GET_AGENTS: (publishState, page, quantity, filter, order) => ({
      publishState,
      page,
      quantity,
      filter,
      order,
    }),
    GET_AGENTS_RESPONSE: (foundAgents) => foundAgents,

    CHANGE_PUBLISH_PERMISSION: (agentId, state) => ({ agentId, state }),
    CHANGE_PUBLISH_PERMISSION_RESPONSE: () => ({}),

    LOGIN_AS_AGENT: (foundAgents) => foundAgents,
    LOGIN_AS_AGENT_RESPONSE: () => {},

    FIND_BY_NAME: (name) => name,
    FIND_BY_NAME_RESPONSE: (foundAgents) => foundAgents,
  },
});

export default agents;
