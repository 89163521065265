import { CarOutlined, PlusOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React from "react";
import Slider from "react-slick";
import copy from "../../../../../assets/images/copy.png";

export const LinesOfProduct = ({ dummyProducts, setViewSelected }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    dots: true,
    speed: 500,
    beforeChange: (current, next) => {
      console.log({ next });
    },
    afterChange: (current) => {
      console.log({ current });
    },
  };

  const dataSource = [
    {
      key: "1",
      name: "A aliquam massa sed.",
      age: "Lorem",
      address: "Lorem",
      link: "https://berrysafe.com/lorem/ipsum.....",
    },
    {
      key: "2",
      name: "A aliquam massa sed.",
      age: "Lorem",
      address: "Lorem",
      link: "https://berrysafe.com/lorem/ipsum.....",
    },
  ];

  const columns = [
    { title: "Nombre", dataIndex: "name", key: "name" },
    { title: "Lorem", dataIndex: "age", key: "age" },
    { title: "Creador", dataIndex: "address", key: "address" },
    {
      title: "Link público",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <div>
          <Button className="btn--table">
            <img src={copy} alt="copy" />
          </Button>
          {text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "button",
      key: "button",
      render: (text) => <Button className="btn--table">...</Button>,
    },
  ];
  console.log(dummyProducts);
  return (
    <div className="LinesOfProduct">
      <div className="head">
        <div className="left">
          <div className="icon">
            <ShoppingOutlined />
          </div>
          <h3>Lineas</h3>
        </div>
        <Button
          className="more btn--secondary"
          onClick={() => setViewSelected("NewLine")}
        >
          <PlusOutlined />
        </Button>
      </div>
      <Slider {...settings}>
        {dummyProducts?.map((item, index) => (
          <div key={index} className="card">
            <div
              className="card--body"
              style={{
                backgroundImage: `linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55)),url(${item.img})`,
              }}
            >
              <div className="icons">
                <CarOutlined />
                <h2>{item.title}</h2>
              </div>
              <div>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="table">
        <div className="head">
          <div className="left">
            <h3>Productos</h3>
          </div>
          <Button className="more btn--secondary">
            <PlusOutlined />
          </Button>
        </div>
        <Table
          scroll={{ x: 1300 }}
          sticky
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    </div>
  );
};
