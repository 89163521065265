import { handleActions } from "redux-actions";

export const INITIAL_STATE = {
  loading: {
    agents: false,
    changePublishPermission: false,
    loginAsAgent: false,
    findByName: false,
  },
  error: {
    agents: undefined,
    changePublishPermission: undefined,
    findByName: undefined,
  },
  success: {
    agents: { Active: [], Unchecked: [], Inactive: [] },
    changePublishPermission: undefined,
    loginAsAgent: undefined,
    findByName: undefined,
  },
};

const reducer = handleActions(
  {
    AGENTS: {
      GET_AGENTS: (state) => ({
        ...state,
        success: { ...state.success, changePublishPermission: undefined },
        loading: { ...state.loading, agents: true },
        error: { ...state.error, agents: undefined },
      }),
      GET_AGENTS_RESPONSE: {
        next(state, { payload: { agents } }) {
          return {
            ...state,
            loading: { ...state.loading, agents: false },
            success: {
              ...state.success,
              agents: { ...state.success.agents, ...agents },
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, agents: message },
            success: { ...state.success, agents: undefined },
            loading: { ...state.loading, agents: false },
          };
        },
      },

      LOGIN_AS_AGENT: (state) => ({
        ...state,
        success: { ...state.success, loginAsAgent: undefined },
        loading: { ...state.loading, loginAsAgent: true },
        error: { ...state.error, loginAsAgent: undefined },
      }),
      LOGIN_AS_AGENT_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, loginAsAgent: false },
            success: {
              ...state.success,
              loginAsAgent: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, loginAsAgent: message },
            success: { ...state.success, loginAsAgent: undefined },
            loading: { ...state.loading, loginAsAgent: false },
          };
        },
      },

      CHANGE_PUBLISH_PERMISSION: (state) => ({
        ...state,
        loading: { ...state.loading, changePublishPermission: true },
        error: { ...state.error, changePublishPermission: undefined },
      }),
      CHANGE_PUBLISH_PERMISSION_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, changePublishPermission: false },
            success: { ...state.success, changePublishPermission: true },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, changePublishPermission: message },
            success: { ...state.success, changePublishPermission: undefined },
            loading: { ...state.loading, changePublishPermission: false },
          };
        },
      },

      FIND_BY_NAME: (state) => ({
        ...state,
        loading: { ...state.loading, findByName: true },
        error: { ...state.error, findByName: undefined },
      }),
      FIND_BY_NAME_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, findByName: false },
            success: {
              ...state.success,
              findByName: payload,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, findByName: message },
            success: { ...state.success, findByName: undefined },
            loading: { ...state.loading, findByName: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
