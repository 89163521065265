/* eslint-disable react/jsx-props-no-spreading */
import { Col, Form, InputNumber, message, Modal, Row } from "antd";
import showTranslatedText from "common/utils/showTranslatedText";
import Loader from "components/loader";
import React, { useEffect } from "react";
import { getI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProductActions from "services/products/actions";

const ProductPercentAssign = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products);

  useEffect(() => {
    if (products.success.percentUpdated) {
      message.success(
        "El porcentaje del producto se ha actualizado exitosamente"
      );
      dispatch(ProductActions.clearSetPercent());
      dispatch(ProductActions.getProductsSpecials());
    }
  }, [products, dispatch]);

  useEffect(() => {
    if (
      !products.success.products &&
      !products.loading.products &&
      !products.error.products
    )
      dispatch(ProductActions.getProductsSpecials());
  }, [products, dispatch]);

  const [form] = Form.useForm();
  const changePercent = (endPoint) => {
    dispatch(
      ProductActions.setPercent(endPoint, form.getFieldsValue()[endPoint])
    );
  };

  return (
    <div className="product-percent-assign">
      <Modal
        className="modal-loader"
        visible={products.loading.percentUpdated || products.loading.products}
        centered
        maskStyle={{ backgroundColor: "#ffffff66" }}
      >
        <Loader />
      </Modal>
      <Form name="percent-assign" form={form}>
        {products.success.products?.map((product) => (
          <Row key={product.endPoint} gutter={10}>
            <Col lg="18" md="18">
              <Form.Item
                name={`${product.endPoint}`}
                label={showTranslatedText(product.title, getI18n()?.language)}
                rules={[
                  {
                    required: true,
                    message: "El valor no puede estar vacío",
                  },
                  {
                    message: "Sólo se permiten números",
                    type: "number",
                  },
                  {
                    type: "number",
                    max: 100,
                    message: "El valor máximo permitido es 100",
                  },
                  {
                    type: "number",
                    min: 0,
                    message: "El valor mínimo permitido es 0",
                  },
                ]}
                initialValue={product.value}
              >
                <InputNumber placeholder="0" />
              </Form.Item>
            </Col>
            <Col md="18" lg="4">
              <button
                type="button"
                onClick={() => changePercent(product.endPoint)}
              >
                Cambiar
              </button>
            </Col>
          </Row>
        ))}
      </Form>
    </div>
  );
};

export default ProductPercentAssign;
