import { Alert, Col, Form, Input, message, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Loader from "../../../../../../../components/loader";
import { auth as AuthActions } from "../../../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const ChangeForgotPassword = ({ changeToLogin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.success.changeForgotPassword) {
      message.success({
        content: "La contraseña se ha restablecido con éxito",
      });
      changeToLogin();
      history.replace("/");
    }
  }, [auth.success.changeForgotPassword, dispatch, changeToLogin, history]);

  const onFinish = (values) =>
    dispatch(AuthActions.changeForgotPassword(values.password));

  const cancelChangePassword = () => {
    history.replace("/");
    changeToLogin();
  };

  return (
    <div className="changeForgotPassword">
      {auth.loading.changeForgotPassword ? (
        <div className="Loader">
          <Loader />
        </div>
      ) : (
        <div>
          <Row>
            <figure className="changeForgotPassword__header">
              <h1 className="changeForgotPassword__headerTitle">
                {t("label.changeForgotPasswordTitle")}
              </h1>

              <p className="changeForgotPassword__headerDescrip">
                {t("label.changeForgotPasswordSubtitle")}
              </p>
            </figure>
          </Row>
          <div className="changeForgotPassword__form">
            <Form {...layout} name="changeForgotPassword" onFinish={onFinish}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("error.password"),
                  },
                  {
                    whitespace: true,
                    min: 8,
                    max: 20,
                    message: t("error.charactersPassword"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t("placeholder.inputPassNew")}
                  className="input-custom"
                />
              </Form.Item>
              <Row>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--secundary"
                    disabled={auth.loading.changeForgotPassword}
                  >
                    {t("button.continue")}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <button
                    className="btn-custom btn-custom--white"
                    onClick={cancelChangePassword}
                  >
                    {t("button.cancel")}
                  </button>
                </Col>
              </Row>
            </Form>
            {auth.error && auth.error.changeForgotPassword && (
              <div className="alert">
                <Alert
                  message={t("error." + auth.error.changeForgotPassword)}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeForgotPassword;
