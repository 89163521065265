import { createActions } from "redux-actions";

const { leads } = createActions({
  LEADS: {
    GET_LEADS: (typeList, page, quantity, filters, order) => ({
      typeList,
      page,
      quantity,
      filters,
      order,
    }),
    GET_LEADS_RESPONSE: (leadsGot) => leadsGot,

    ASSIGN_AGENT: (lead, agent) => ({
      lead,
      agent,
    }),
    ASSIGN_AGENT_RESPONSE: () => {},
    CLEAR_ASSIGN_AGENT: () => {},

    CHANGE_AGENT: (lead, agent) => ({ lead, agent }),
  },
});

export default leads;
