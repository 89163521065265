/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import ModalLoader from "components/loader/ModalLoader";
import offersActions from "services/offers/actions";
import moment from "moment";
import closeSvg from "assets/images/close.svg";
import searchSvg from "assets/images/Search-purple.svg";
import ModalDetailFeedback from "../components/ModalDetailFeedback";

const ListFeedback = () => {
  const dispatch = useDispatch();
  const { listFeedback } = useSelector((state) => state.offers.success);
  const { listFeedback: listFeedbackLoading } = useSelector(
    (state) => state.offers.loading
  );

  const [objectSearch, setObjectSearch] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(offersActions.getFeedback(page));
  }, [dispatch]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(
      offersActions.getFeedback({
        page: pageNumber,
        name: objectSearch,
      })
    );
  };

  const handleWriting = (e) => {
    setObjectSearch(e.target.value);
  };

  const handleClear = () => {
    setObjectSearch("");
    const values = { page };
    dispatch(offersActions.getFeedback(values));
  };

  const handleSearch = (policy) => {
    const values = { page: 1, policy };
    dispatch(offersActions.getFeedback(values));
  };
  return (
    <div>
      <div className="insuransers-head">
        <h1 className="agents__title">Feedback</h1>
        <Input
          placeholder="Busca por numero de poliza"
          className="search-line"
          type="number"
          value={objectSearch}
          onChange={(e) => handleWriting(e)}
          suffix={
            <>
              {objectSearch !== "" && (
                <Button onClick={() => handleClear()}>
                  <img
                    src={closeSvg}
                    className="site-form-item-icon"
                    alt="searcg"
                  />
                </Button>
              )}
              <Button onClick={() => handleSearch(objectSearch)}>
                <img
                  src={searchSvg}
                  className="site-form-item-icon"
                  alt="searcg"
                />
              </Button>
            </>
          }
        />
      </div>
      <Table sticky dataSource={listFeedback?.items}>
        <Column
          title="Numero de póliza"
          dataIndex="policyNumber"
          key="policyNumber"
          className="item-name"
        />
        <Column
          title="Nombre Agente"
          dataIndex="agentName"
          key="agentName"
          className="item"
        />
        <Column
          title="Aseguradora Ganadora"
          key="insuranceCarrier"
          className="item"
          render={(_, record) => {
            return <span>{record.insuranceCarrier?.name}</span>;
          }}
        />
        <Column
          title="Fecha de registro del feedback"
          key="dateUpload"
          className="item"
          render={(_, record) => {
            return (
              <span>
                {moment(record.renovation?.createdAt)
                  .locale("es-mx")
                  .format("DD/MM/YYYY")}
              </span>
            );
          }}
        />
        <Column
          title="Valor ganado del feedback"
          key="amount"
          className="item"
          render={(_, record) => {
            return <span>${record.amount}USD</span>;
          }}
        />
        <Column
          title="Detalle"
          key="detail"
          className="item"
          render={(_, record) => {
            return <ModalDetailFeedback itemFeedback={record} />;
          }}
        />
      </Table>
      {listFeedbackLoading && <ModalLoader />}

      {listFeedback?.meta?.totalPages >= 1 && (
        <Pagination
          defaultCurrent={page}
          total={listFeedback?.meta?.totalItems}
          pageSize={10}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default ListFeedback;
