/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Auth from "./auth/Auth";
import CheckEmail from "./checkEmail";
import EmailVerification from "./emailVerification";
import TokenValidation from "./tokenValidation/TokenValidation";

const NoMatch = ({ location }) => {
  const history = useHistory();
  useEffect(() => {
    history.replace("/");
  }, [history]);

  return (
    <h3>
      {" "}
      No match for
      <code>{location.pathname}</code>
    </h3>
  );
};

const Public = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Auth} />
        <Route path="/checkEmail" component={CheckEmail} />
        <Route path="/emailVerification" component={EmailVerification} />
        <Route exact path="/sso/:token" component={TokenValidation} />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
};

export default Public;
