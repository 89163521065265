import { Modal, Tabs } from "antd";
import ConfirmAlert from "components/ConfirmAlert/ConfirmAlert";
import { instanceOf } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import iconAgents from "../../../assets/icon/icon-agents.svg";
import Loader from "../../../components/loader";
import LeadsActions from "../../../services/leads/actions";
import AgentAssign from "./components/AgentAssign";
import ConfirmedTable from "./tables/confirmed/Confirmed";
import TableToConfirm from "./tables/ToConfirm/TableToConfirm";

const { TabPane } = Tabs;
const initialColumnOrders = {
  name: {
    column: "person.name",
    value: undefined,
  },
  requestDate: {
    column: "lead.requestDate",
    value: "ASC",
  },
  product: {
    column: "desiredProduct.id",
    value: undefined,
  },
  origin: {
    column: "origin.name",
    value: undefined,
  },
};

const Leads = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [typeList, setTypeList] = useState("toBeConfirmed");
  const [showAssignModal, setShowAssignModal] = useState();
  const [page, setPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const [filter, setFilter] = useState([]);
  const [orderColumns, setOrderColumns] = useState(initialColumnOrders);
  const [activeOrderColumns, setActiveOrderColumns] = useState("requestDate");
  const [searchValue, setSearchValue] = useState("");
  const [toReassign, setToReAssign] = useState({});
  const [warningVisible, setWarningVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState();

  const leads = useSelector((state) => state.leads);

  const getLeads = useCallback(() => {
    dispatch(
      LeadsActions.getLeads(
        typeList,
        page,
        quantity,
        filter,
        orderColumns[activeOrderColumns]
      )
    );
  }, [
    dispatch,
    typeList,
    page,
    quantity,
    filter,
    orderColumns,
    activeOrderColumns,
  ]);

  useEffect(() => {
    if (leads.success.assignAgent) {
      dispatch(LeadsActions.clearAssignAgent());
      getLeads();
    }
  }, [dispatch, leads.success.assignAgent, getLeads]);

  useEffect(() => {
    if (!leads.loading.leads && !leads.success.leads && !leads.error.leads)
      getLeads();

    if (leads.success.changePublishPermission) getLeads();
  }, [leads, dispatch, getLeads]);

  useEffect(() => {
    setShowAssignModal(match.params.agentKey);
  }, [match.params]);

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  const resetStatesByTabChange = (type) => {
    setFilter([]);
    setOrderColumns(initialColumnOrders);
    setActiveOrderColumns("createdAt");
    setTypeList(type);
    setIsSearching(false);
    setSearchValue("");
    setQuantity(10);
    dispatch(
      LeadsActions.getLeads(type, 0, 10, [], initialColumnOrders.requestDate)
    );
  };

  const openDetail = (lead) => {
    setToReAssign(lead);
    setShowAssignModal(true);
  };

  const assignAgent = (lead) => {
    setWarningVisible(true);
    setSelectedLead(lead);
  };

  const sort = (columnName) => {
    const newOrder = orderColumns;

    Object.keys(newOrder).map((columnkey) => {
      if (columnkey !== columnName) {
        newOrder[columnkey].value = undefined;
        return columnkey;
      }
      if (newOrder[columnkey].value === undefined) {
        newOrder[columnkey].value = "ASC";
        return columnkey;
      }

      if (newOrder[columnkey].value === "ASC") {
        newOrder[columnkey].value = "DESC";
        return columnkey;
      }

      if (newOrder[columnkey].value === "DESC") {
        newOrder[columnkey].value = undefined;
        return columnkey;
      }

      return columnkey;
    });

    setOrderColumns(newOrder);
    setActiveOrderColumns(columnName);
    dispatch(
      LeadsActions.getLeads(
        typeList,
        page,
        quantity,
        filter,
        newOrder[columnName]
      )
    );
  };

  const search = () => {
    if (!isSearching) return setIsSearching(true);

    setPage(1);
    setFilter([
      {
        column: "contacts.value",
        value: searchValue,
        type: "varchar",
      },
    ]);
    return null;
  };

  return (
    <div className="agents">
      <ConfirmAlert
        isVisible={warningVisible}
        message="¿Estás seguro de asignar el lead?"
        description="Una vez asignado el lead, no podrás revertir la acción"
        onOk={() => {
          setWarningVisible(false);
          dispatch(
            LeadsActions.assignAgent(
              selectedLead.id,
              selectedLead.preassignedAgent.id
            )
          );
        }}
        onAcceptClick={() => {
          setWarningVisible(false);
          dispatch(
            LeadsActions.assignAgent(
              selectedLead.id,
              selectedLead.preassignedAgent.id
            )
          );
        }}
        onCancel={() => setWarningVisible(false)}
        onDeniedClick={() => setWarningVisible(false)}
        okClassName="yes"
        cancelClassName="no"
      />
      <h1 className="agents__title">
        <img src={iconAgents} alt="" />
        {t("label.leadsTitle")}
      </h1>

      <Modal
        visible={!!showAssignModal}
        onCancel={() => setShowAssignModal(false)}
        footer={null}
      >
        <AgentAssign
          lead={toReassign}
          close={() => setShowAssignModal(false)}
        />
      </Modal>

      <div className="agents__tabs">
        <Tabs
          defaultActiveKey="Active"
          onChange={(key) => {
            resetStatesByTabChange(key);
          }}
        >
          <TabPane tab={t("label.toBeConfirmed")} key="toBeConfirmed">
            {leads.loading.agents && <Loader />}
            <TableToConfirm
              data={leads.success.leads?.toBeConfirmed}
              openDetail={openDetail}
              setPage={setPage}
              setQuantity={setQuantity}
              quantity={quantity}
              columnOrder={orderColumns}
              sort={sort}
              total={leads.success.leads?.total}
              search={search}
              isSearching={isSearching}
              setFilter={setSearchValue}
              assignAgent={assignAgent}
            />
          </TabPane>
          <TabPane tab={t("label.assign")} key="process">
            <ConfirmedTable
              data={leads.success.leads?.process}
              openDetail={openDetail}
              setPage={setPage}
              setQuantity={setQuantity}
              quantity={quantity}
              columnOrder={orderColumns}
              sort={sort}
              total={leads.success.leads?.total}
              search={search}
              isSearching={isSearching}
              setFilter={setSearchValue}
              assignAgent={assignAgent}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

Leads.propTypes = {
  match: instanceOf(Object).isRequired,
};

export default Leads;
