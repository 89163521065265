/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { all, put, takeLatest } from "redux-saga/effects";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import offersActions from "./actions";

function* getOffers({ payload }) {
  const url = "/pia/new/offers/admin";

  const params = {
    page: payload.page.page || 1,
    name: payload.page.insuranceKey || undefined,
    endDate: payload.page.endDate || undefined,
    quantity: 10,
    startDate: payload.page.startDate || undefined,
  };
  if (!params.name) {
    delete params.name;
  }
  if (!params.startDate) {
    delete params.startDate;
  }
  if (!params.endDate) {
    delete params.endDate;
  }

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getOffersResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getOffersResponse(errorFormatted));
}

function* getQuotes({ payload }) {
  const url = "/pia/new/admin/quotes";
  const params = {
    page: payload.page.pageQuotes,
    insuranceKey: payload.page.insuranceKey,
    quantity: 10,
    endDate: payload.page.endDate || undefined,
    startDate: payload.page.startDate || undefined,
  };

  if (!params.insuranceKey) delete params.insuranceKey;
  if (!params.endDate) delete params.endDate;
  if (!params.startDate) delete params.startDate;

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getQuotesResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getQuotesResponse(errorFormatted));
}
function* getQuotesReport({ payload }) {
  const url = "/pia/new/admin/quotes/report";
  const params = {
    page: payload.page.page,
    insuranceKey: payload.page.insuranceKey,
    quantity: 10,
    endDate: payload.page.endDate || undefined,
    startDate: payload.page.startDate || undefined,
  };

  if (!params.insuranceKey) delete params.insuranceKey;
  if (!params.endDate) delete params.endDate;
  if (!params.startDate) delete params.startDate;

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getQuotesReportResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getQuotesReportResponse(errorFormatted));
}

function* getFeedback({ payload }) {
  const url = "/pia/new/admin/feedbacks";
  const params = {
    page: payload.feedback.page ?? 1,
    name: payload.feedback.policy,
    endDate: payload.feedback.endDate || undefined,
    startDate: payload.feedback.startDate || undefined,
    insuranceKey: payload.feedback.insuranceKey,
    quantity: payload.feedback.quantity || 10,
  };

  if (!params.name) delete params.name;
  if (!params.insuranceKey) delete params.insuranceKey;
  if (!params.startDate) {
    delete params.startDate;
  }
  if (!params.endDate) {
    delete params.endDate;
  }

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getFeedbackResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getFeedbackResponse(errorFormatted));
}

function* getFeedbackAll({ payload }) {
  const url = "/pia/new/admin/feedbacks/report";
  const fechaActual = new Date();
  const fechaActual2 = new Date();

  fechaActual2.setMonth(fechaActual2.getMonth() + 1);

  const dia = fechaActual.getDate(); // Día del mes
  const mes = fechaActual.getMonth() + 1; // Mes (comienza desde 0, por lo que sumamos 1)
  const anio = fechaActual.getFullYear(); // Año

  const dia2 = fechaActual2.getDate(); // Día del mes
  const mes2 = fechaActual2.getMonth() + 1; // Mes (comienza desde 0, por lo que sumamos 1)
  const anio2 = fechaActual2.getFullYear(); // Año

  const currentDate = `${anio}/${mes}/${dia}`;
  const nextDate = `${anio2}/${mes2}/${dia2}`;

  const params = {
    name: payload.feedback.policy,
    endDate: payload.feedback.endDate || nextDate,
    startDate: payload.feedback.startDate || currentDate,
    insuranceKey: payload.feedback.insuranceKey,
  };

  if (!params.name) delete params.name;
  if (!params.insuranceKey) delete params.insuranceKey;
  if (!params.startDate) {
    delete params.startDate;
  }
  if (!params.endDate) {
    delete params.endDate;
  }

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getFeedbackAllResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getFeedbackAllResponse(errorFormatted));
}

function* getBillingList({ payload }) {
  const url = "/admin/billing/all";

  const params = {
    page: payload.page ?? 1,
    quantity: 10,
    state: "paid",
  };

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getBillingListResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getBillingListResponse(errorFormatted));
}

function* getExpiredOffers({ payload }) {
  const url = "/pia/new/admin/expired/offers";
  const params = {
    page: payload.expired.page ?? 1,
    endDate: payload.expired.endDate || undefined,
    startDate: payload.expired.startDate || undefined,
    name: payload.expired.name || undefined,
    quantity: 10,
    expired: false,
    order: "asc",
  };
  if (!params.name) {
    delete params.name;
  }
  if (!params.startDate) {
    delete params.startDate;
  }
  if (!params.endDate) {
    delete params.endDate;
  }
  // if (!params.policy) delete params.policy;

  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(offersActions.getExpiredOffersResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(offersActions.getExpiredOffersResponse(errorFormatted));
}

function* ActionWatcher() {
  yield takeLatest(offersActions.getOffers, getOffers);
  yield takeLatest(offersActions.getQuotes, getQuotes);
  yield takeLatest(offersActions.getFeedback, getFeedback);
  yield takeLatest(offersActions.getExpiredOffers, getExpiredOffers);
  yield takeLatest(offersActions.getBillingList, getBillingList);
  yield takeLatest(offersActions.getQuotesReport, getQuotesReport);
  yield takeLatest(offersActions.getFeedbackAll, getFeedbackAll);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
