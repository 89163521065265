import React from "react";
import LoaderImg from "../../assets/images/loader.gif";

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderImg} className="loader__img" alt="" />
    </div>
  );
};

export default Loader;
