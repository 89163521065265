/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Input, Menu, Pagination, Select, Table } from "antd";
import React from "react";
import IconLupa from "../../../../../assets/icon/icon-lupa.svg";
import IconUser from "../../../../../assets/icon/icon-user-table.png";
import SortSvg from "../../../../../assets/icon/sort_down.svg";
import NoOrderSvg from "../../../../../assets/icon/no-order.svg";

const { Option } = Select;
const menu = (cancelAgent, record, loginAsAgent) => (
  <Menu>
    <Menu.Item>
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          loginAsAgent(record);
        }}
      >
        Ingresar
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          cancelAgent(record);
        }}
      >
        Rechazar
      </a>
    </Menu.Item>
  </Menu>
);

const columns = (openDetail, cancelAgent, columnOrder, sort, loginAsAgent) => {
  const renderSort = (column) => {
    if (columnOrder[column].value === "ASC")
      return (
        <a
          href="./"
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img alt="Sort" src={SortSvg} />
        </a>
      );

    if (columnOrder[column].value === "DESC")
      return (
        <a
          href="./"
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img
            alt="Sort"
            src={SortSvg}
            style={{
              transform: "rotate(180deg)",
            }}
          />
        </a>
      );

    return (
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          sort(column);
        }}
      >
        <img alt="Sort" src={NoOrderSvg} />
      </a>
    );
  };

  return [
    {
      title: () => (
        <div>
          Nombre
          {renderSort("name")}
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a
          href="./"
          className="table-cell table-cell--name"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <img src={IconUser} alt="" />
          {record.user.person.name}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <a href="./" className="table-cell table-cell--email">
          {record.user.email}
        </a>
      ),
    },
    {
      title: "Url",
      dataIndex: "endPoint",
      key: "endPoint",
      render: (text) => <p className="table-cell">{text}</p>,
    },
    {
      title: () => (
        <div>
          Fecha
          {renderSort("createdAt")}
        </div>
      ),
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <p className="table-cell">{record.createdAt}</p>
      ),
    },
    {
      title: () => (
        <div>
          Amasfac
          {renderSort("amasfac")}
        </div>
      ),
      width: 150,
      dataIndex: "line",
      key: "line",
      render: (text, record) => {
        if (record.association) return <label>Si</label>;
        return <label>No</label>;
      },
    },

    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={menu(cancelAgent, record, loginAsAgent)}
          trigger={["click"]}
          overlayClassName="table-dropdown-custom"
          placement="bottomRight"
        >
          <a
            href="./"
            className="custom-trigger-btn"
            onClick={(e) => e.preventDefault()}
          >
            Editar
            <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];
};

const TableApproved = ({
  data,
  openDetail,
  setPage,
  setQuantity,
  quantity,
  search,
  isSearching,
  setFilter,
  cancelAgent,
  columnOrder,
  sort,
  loginAsAgent,
  total,
  isPage,
}) => {
  const handleCant = (value) => {
    setQuantity(parseInt(value));
    const currentPage = parseInt(total) / parseInt(value);
    if (isPage > Math.trunc(currentPage)) {
      if (currentPage > Math.trunc(currentPage)) {
        setPage(Math.trunc(currentPage) + 1);
      } else {
        setPage(Math.trunc(currentPage));
      }
    }
  };
  return (
    <div>
      <div className="actions-head">
        <div className="actions-head__select">
          <span className="actions-head__selectLabel">Ver</span>
          <Select
            value={quantity}
            defaultValue="10"
            onChange={(value) => handleCant(value)}
          >
            <Option value="5">5</Option>
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="25">25</Option>
          </Select>
        </div>

        <div className="actions-head__search">
          {isSearching && (
            <Input
              onChange={(e) => setFilter(e.target.value)}
              className="actions-head__searchInput"
              placeholder="Buscar"
            ></Input>
          )}
          <a href="./" onClick={(e) => e.preventDefault()}>
            <figure onClick={() => search()}>
              <img src={IconLupa} alt="" />
            </figure>
          </a>
        </div>
      </div>
      <Table
        scroll={{ x: 1300 }}
        sticky
        pagination={false}
        columns={columns(
          openDetail,
          cancelAgent,
          columnOrder,
          sort,
          loginAsAgent
        )}
        dataSource={data}
        className="table-agents"
      />
      <Pagination
        defaultCurrent={1}
        onChange={(page) => {
          setPage(page);
        }}
        total={total}
        pageSize={quantity}
      />
    </div>
  );
};

export default TableApproved;
