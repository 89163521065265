export const en = {
  "login_title": "Login"
}

const label = {
  loginTitle: 'Inicio de sesión',
  createAccountWith: 'Crea una cuenta con',
  email: 'Correo',
  password: 'Contraseña',
  hello: '¡Hola!',
  dontHaveAnAccount: '¿No tienes una cuenta?',
  alreadyHaveAnAccount: 'Ya tienes una cuenta?',
  or: 'o',
  name: 'Nombre',
  phone: 'Teléfono',
  forgotPasswordText: ' Ingresa tu correo electrónico y te enviaremos un link para que puedas cambiar tu contraseña',
  newPassword: 'Nueva Contraseña'
}

const button = {
  goLogin: 'Iniciar sesión',
  login: 'Iniciar sesión',
  go: 'Iniciar',
  goSignUp: 'Regístrate',
  register: 'Registrarme',
  logout: 'Cerrar sesión',
  haveAnAccessCode: 'Tienes código de acceso?',
  forgetPassword: '¿Olvidaste tu contraseña?',
  forgotPassword: 'Olvidé mi contraseña',
  loginFacebook: 'Iniciar sesión con Facebook',
  loginGoogle: 'Iniciar sesión con Google',
  loginTwitter: 'Iniciar sesión con Twitter',
  signUpFacebook: 'Registrate con Facebook',
  signUpGoogle: 'Registrate con Google',
  signUpTwitter: 'Registrate con Twitter',
  cancel: 'Cancelar',
  Send: 'Enviar',
  update: 'Actualizar',
  detail: 'Detalle',

}
const error = {
  email: 'Por favor ingresa un email válido',
  charactersEmail: 'El correo electrónico no puede contener más de 200 caracteres',
  password: 'Por favor ingresa tu contraseña',
  charactersPassword: 'La contraseña debe estar entre 8 y 20 caracteres',
  name: 'Por favor ingresa tu nombre',
  charactersName: 'El nombre no puede contener más de 50 caracteres',
  lastname: 'Por favor ingresa tu apellido',
  charactersLastname: 'El apellido no puede contener más de 50 caracteres',
  phone: 'Por favor ingresa tu número de teléfono mexicano',
  charactersPhone: 'El teléfono no puede contener más de 50 caracteres',
  confirmPassword: 'Por favor confirma tu contraseña',
}

const alert = {
  success: 'Registro exitoso'
}

export {
  label,
  button,
  error,
  alert
}