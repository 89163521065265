/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useDispatch, useSelector } from "react-redux";
import ModalLoader from "components/loader/ModalLoader";
import billingActions from "services/billing/actions";
import donwloadSvg from "assets/images/donwload-purple-svg.svg";
import moment from "moment";
import ModalConfirm from "./components/ModalConfirm";

const PiaPay = () => {
  const dispatch = useDispatch();
  const { listBilling } = useSelector((state) => state.billing.success);
  const { listBilling: listBillingLoading } = useSelector(
    (state) => state.offers.loading
  );

  const [listBillings, setListBillings] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(billingActions.getAllBilling());
  }, [dispatch]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    const newList = [];
    for (let index = 0; index < listBilling?.items?.length; index++) {
      const element = listBilling?.items[index];
      newList.push({
        agentName: element?.agent?.user?.person?.name,
        key: element?.id,
        agentEmail: element?.agent?.user?.email,
        status: element?.billingStatus,
        file: element?.piaFile?.url,
        amount: element?.amount ?? 0,
        paymentAccount: element?.paymentAccount,
        createdAt: element?.createdAt,
        allItems: element,
      });
    }
    setListBillings(newList);
  }, [listBilling]);

  return (
    <div>
      <div className="insuransers_table">
        <Table sticky dataSource={listBillings}>
          <Column
            title="Agente solicitante"
            dataIndex="agentName"
            key="agentName"
            className="item"
          />
          <Column
            title="Monto"
            key="amount"
            className="item"
            render={(_, record) => {
              return <span>${record?.amount} USD</span>;
            }}
          />
          <Column
            title="Fecha"
            key="createdAt"
            className="item"
            render={(_, record) => {
              return (
                <span>
                  {moment(record.createdAt)
                    .locale("es-mx")
                    .format("DD/MM/YYYY hh:mm A")}
                </span>
              );
            }}
          />
          <Column
            title="Estado"
            key="status"
            render={(_, record) => {
              return (
                <span>
                  {record.status === "pending" && "Pendiente"}
                  {record.status === "in_process" && "En proceso"}
                  {record.status === "verification" && "En verificación"}
                  {record.status === "rejected" && "Rechazada"}
                  {record.status === "paid" && "Pagada"}
                  {record.status === "overdue" && "Vencida"}
                  {record.status === "void" && "Anulada"}
                </span>
              );
            }}
            className="item"
          />
          <Column
            title="Cuenta de pago"
            key="paymentAccount"
            className="item"
            render={(_, record) => {
              return <span>{record.paymentAccount?.email}</span>;
            }}
          />
          <Column
            title="Factura"
            key="file"
            className="item"
            render={(_, record) => {
              return (
                <a
                  className="donwload-file"
                  target="_blank"
                  href={`${record.file}`}
                  rel="noopener noreferrer"
                  download={`${record.agentEmail}`}
                >
                  Descargar <img src={donwloadSvg} alt="donwload" />
                </a>
              );
            }}
          />
          <Column
            title=""
            key="detail"
            className="item"
            render={(_, record) => {
              return (
                <>
                  {record?.status === "pending" && (
                    <ModalConfirm itemBilling={record} />
                  )}
                </>
              );
            }}
          />
        </Table>
      </div>
      {listBillingLoading && <ModalLoader />}
      {listBilling?.meta?.totalPages >= 1 && (
        <Pagination
          defaultCurrent={page}
          total={listBilling?.meta?.totalItems}
          pageSize={10}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default PiaPay;
