import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { auth } from "../services/auth/actions";
import authReducer from "../services/auth/reducer";
import agentReducer from "../services/agents/reducer";
import userReducer from "../services/users/reducer";
import routesReducer from "../services/routes/reducer";
import leadsReducer from "../services/leads/reducer";
import productReducer from "../services/products/reducer";
import renovationsReducer from "../services/renovations/reducer";
import insuransersReducer from "../services/insuransers/reducer";
import offersReducer from "../services/offers/reducer";
import billingReducer from "../services/billing/reducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    agents: agentReducer,
    users: userReducer,
    routes: routesReducer,
    leads: leadsReducer,
    products: productReducer,
    renovations: renovationsReducer,
    insuransers: insuransersReducer,
    offers: offersReducer,
    billing: billingReducer,
  });

const rootReducer = (history) => {
  return (state, action) => {
    // eslint-disable-next-line no-param-reassign
    if (action.type === auth.logout) state = undefined;
    return appReducer(history)(state, action);
  };
};

export default rootReducer;
