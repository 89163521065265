import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import BackLogin from "../../../assets/images/back-login.png";
import IconOpps from "../../../assets/images/ops.svg";
import { auth as AuthActions } from "../../../services/auth/actions";
import ChangeForgotPassword from "./components/forgotPassword/components/changeForgotPassword/ChangeForgotPassword";
import ValidateCode from "./components/forgotPassword/components/validateCode/ValidateCode";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Login from "./components/login/Login";
import logo from "../../../assets/images/Logo-log.png";

const forgotPasswordSteps = [
  "forgotPassword",
  "validateCode",
  "changePassword",
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Auth = () => {
  let query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [whoIsActive, setWhoIsActive] = useState("login");
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      query.get("forgotPasswordCode") !== null &&
      !auth.success.codeVerification &&
      !auth.success.changeForgotPassword
    ) {
      setWhoIsActive("validateCode");
    }
  }, [query, auth]);

  const changeToForgotPassword = () => setWhoIsActive("forgotPassword");

  const changeToValidateCode = () => {
    message.success(
      "Te hemos enviado un código de validación a tu correo electrónico"
    );
    setWhoIsActive("login");
  };

  const changeToChangePassword = () => setWhoIsActive("changePassword");

  const changeToLogin = () => {
    dispatch(AuthActions.cancelForgotPassword());
    setWhoIsActive("login");
  };

  return (
    <Row className="full-height">
      <Col className="cont-img" xs={12} sm={12} md={12} lg={12}>
        <div className="col-login">
          <img src={logo} className="logo-berry" alt="berry" />
          <div
            className="col-login__welcome"
            style={{ backgroundImage: `url(${BackLogin})` }}
          >
            {forgotPasswordSteps.some((step) => step === whoIsActive) ? (
              <>
                <figure>
                  <img src={IconOpps} alt="icon-opps" />
                </figure>
                <h1 className="col-login__welcomeForgotPassword">
                  {t("label.forgotPasswordWelcome")}
                </h1>
              </>
            ) : (
              <h1 className="col-login__welcomeTitle">{t("label.hello")}</h1>
            )}
            {whoIsActive === "login" && (
              <h3 className="col-login__welcomeSubtitle">
                {t("label.welcomeSuperAdmin")}
              </h3>
            )}
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className="col-signup">
        {whoIsActive === "login" && (
          <Login changeToForgotPassword={changeToForgotPassword} />
        )}

        {whoIsActive === "forgotPassword" && (
          <ForgotPassword
            cancelForgotPassword={changeToLogin}
            changeToValidateCode={changeToValidateCode}
          />
        )}

        {whoIsActive === "validateCode" && (
          <ValidateCode
            urlCode={query.get("forgotPasswordCode")}
            changeToChangePassword={changeToChangePassword}
            cancelForgotPassword={changeToLogin}
          />
        )}

        {whoIsActive === "changePassword" && (
          <ChangeForgotPassword changeToLogin={changeToLogin} />
        )}
      </Col>
    </Row>
  );
};

export default Auth;
