import React from "react";
import { ShoppingOutlined } from '@ant-design/icons';
import { Upload, message, Button, Input } from "antd";
import pen from "../../../../../assets/images/pen.png";
import picture from "../../../../../assets/images/picture.png";
import layout from "../../../../../assets/images/layout.png";
const { TextArea } = Input;

export const NewLine = ({ setViewSelected }) => {

	const props = {
		name: 'file',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};

	return (
		<div className="NewLine">
			<div className="head">
				<div className="left">
					<div className="icon"><ShoppingOutlined /></div>
					<h3>Lineas</h3>
				</div>
				<div>
					<Button className="btn--cancel" onClick={() => setViewSelected('LinesOfProduct')}>Cancelar</Button>
					<Button className="btn btn--secondary" onClick={() => setViewSelected('NewProduct')}>Guardar</Button>
				</div>
			</div>
			<div className="content">
				<div className="content__Layout">
					<h4>Layout</h4>
					<div className="Layout">
						<img src={layout} alt="layout" className="layout--img"/>
					</div>
				</div>
				<div className="content__Layout right">
					<div className="content__Layout--head">
						<div>
							<h4>Imagen de fondo</h4>
							<Upload className="upload" {...props}>
								<Button><img src={picture} alt="upload" /><span>Subir imagen</span></Button>
							</Upload>
						</div>
						<div>
							<h4>Imagen de fondo</h4>
							<Upload className="upload" {...props}>
								<Button><img src={pen} alt="pen" /><span>Subir icono</span></Button>
							</Upload>
						</div>
					</div>
					<div className="content__Layout--titles">
						<div className="item">
							<h4>Título</h4>
							<Input placeholder="Seguro de autos" />
						</div>
						<div className="item">
							<h4>Título resumen</h4>
							<Input placeholder="Autos" />
						</div>
					</div>
					<div className="textArea">
						<h4>Descripción</h4>
						<TextArea placeholder="Nisl enim, nisi, pharetra fames blandit sed. Pharetra egestas habitasse urna imperdiet gravida facilisi commodo. Quam eget habitasse vestibulum bibendum mus scelerisque." rows={4} />
					</div>
				</div>
			</div>
		</div>
	);
}