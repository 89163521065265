/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import { all, put, select, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-named-as-default
import branchList from "common/utils/policiesBranchList";
import statusList from "common/utils/policiesStatusList";
import { FILE_VIEWER_HOST } from "common/config/config";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import RenovationsActions from "./actions";
import * as FavoritieStorage from "../../common/storage/Favorities";

function* getPolicies() {
  const url = "/pia/admin";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(RenovationsActions.getPoliciesResponse(response.detail));
  }

  return yield put(
    RenovationsActions.getPoliciesResponse(parseError(response))
  );
}

function* getNextRenovations() {
  const url = "/pia/admin/summary";

  const response = yield Api.get(url);
  if (response.success) {
    response.detail = {
      ...response.detail,
      image: `${FILE_VIEWER_HOST}/images/insurance-carrier/pia/${response?.detail?.insuranceTop?.key}.svg`,
    };
    return yield put(
      RenovationsActions.getNextRenovationsResponse(response?.detail)
    );
  }
}

function* getQuotes() {
  const url = "/pia/admin/quoted";

  const response = yield Api.get(url);
  if (response.success) {
    return yield put(RenovationsActions.getQuotesResponse(response?.detail));
  }

  let err;
  if (response.error) err = new TypeError(response.error);
  else err = new TypeError("ERROR_GETTING_ACCOUNTS");

  return yield put(RenovationsActions.getQuotesResponse(err, response));
}

function* getListPolicies() {
  const url = "/pia/admin";
  const response = yield Api.get(url);
  if (response.success) {
    return yield put(
      RenovationsActions.getListPoliciesResponse(response.detail)
    );
  }

  return yield put(
    RenovationsActions.getListPoliciesResponse(parseError(response))
  );
}

function* getCover({ payload }) {
  const url = "/pia/";
  const urlEnd = "/cover";
  const response = yield Api.get(url + payload.cover + urlEnd);
  if (response.success) {
    return yield put(RenovationsActions.getCoverResponse(response.detail));
  }

  return yield put(RenovationsActions.getCoverResponse(parseError(response)));
}

function* getPolicy({ payload }) {
  const url = "/pia/";
  const response = yield Api.get(url + payload.policy);
  if (response.success) {
    yield put(
      RenovationsActions.getCover(response.detail.General[0]?.NumPolicy)
    );
    return yield put(RenovationsActions.getPolicyResponse(response.detail));
  }

  return yield put(RenovationsActions.getPolicyResponse(parseError(response)));
}

function* getFilteredPolicies() {
  const filters = yield select((state) => state.renovations.filters);

  const filtersParsed = {};
  Object.keys(filters).map((key) => {
    if (key === "Buscar") {
      filtersParsed[key] = filters[key];
      return key;
    }
    filtersParsed[key] = filters[key]
      .filter((value) => value !== "0")
      .join(",");
    return key;
  });

  Object.keys(filtersParsed)
    .filter((key) => filtersParsed[key] === "")
    .forEach((key) => {
      filtersParsed[key] = "0";
    });

  if (Object.keys(filtersParsed).find((key) => filtersParsed[key] === ""))
    return yield put(RenovationsActions.getLastPolicies());
  if (!filtersParsed.Buscar) delete filtersParsed.Buscar;

  const initialPolicies = [];

  const axaPolicies = yield Api.get(`/pia`, {
    FechaInicial: filters.FechaInicial[0],
    FechaFinal: filters.FechaFinal[0],
    Ramos: filters.Ramos.filter((ramo) => ramo !== "" || ramo === 0)
      .map(
        (branch) =>
          branchList.find((branchListItem) => branchListItem.id === branch)
            ?.title
      )
      .join(","),
    Estado: filters.Estado.filter((status) => status !== "" || status === 0)
      .map(
        (status) =>
          statusList.find((statusListItem) => statusListItem.id === status)
            ?.title
      )
      .join(","),
  });

  if (axaPolicies.success) {
    initialPolicies.unshift(...axaPolicies.detail);
    return yield put(
      RenovationsActions.getPoliciesResponse(initialPolicies, 1)
    );
  }

  let err;
  if (axaPolicies.error) err = new TypeError(axaPolicies.error);
  else err = new TypeError("ERROR_GETTING_FILTERED_POLICIES");

  return yield put(RenovationsActions.getAllPoliciesResponse(err, axaPolicies));
}

function* setFavorities({ payload }) {
  const response = yield FavoritieStorage.save(payload);
  if (response.success === "OK") {
    yield FavoritieStorage.save(payload);
  }
}

function* setPolicyPdf({ payload }) {
  const url = "/pia/quotation";
  const response = yield Api.post(url, payload, true);
  if (!response.success)
    yield put(RenovationsActions.setPolicyPdfResponse(parseError(response)));

  yield put(RenovationsActions.setPolicyPdfResponse());
}

function* ActionWatcher() {
  yield takeLatest(RenovationsActions.getPolicies, getPolicies);
  yield takeLatest(RenovationsActions.getPolicy, getPolicy);
  yield takeLatest(RenovationsActions.setFilters, getFilteredPolicies);
  yield takeLatest(RenovationsActions.setFavorities, setFavorities);
  yield takeLatest(RenovationsActions.setPolicyPdf, setPolicyPdf);
  yield takeLatest(RenovationsActions.getListPolicies, getListPolicies);
  yield takeLatest(RenovationsActions.getNextRenovations, getNextRenovations);
  yield takeLatest(RenovationsActions.getQuotes, getQuotes);
  yield takeLatest(RenovationsActions.getCover, getCover);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
