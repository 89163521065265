import { AutoComplete, Button } from "antd";
import { func, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentActions from "../../../../services/agents/actions";
import LeadsActions from "../../../../services/leads/actions";

const { Option } = AutoComplete;

const AgentAssign = ({ lead, close }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const agents = useSelector((state) => state.agents);
  const [selectedAgent, setSelectedAgent] = useState();

  const onSelect = (data) => {
    setSelectedAgent(options.find((option) => option.id === data));
    setSearchValue(
      options.find((option) => option.id === data).user.person.name
    );
  };

  useEffect(() => {
    if (agents.success.findByName)
      setOptions(
        agents.success.findByName.map((agent) => ({
          ...agent,
          value: agent.user.person.name,
          image: agent.profilePhoto
            ? agent.profilePhoto
            : "https://stg-fileviewer.berrysafe.com/images/agent/agent.svg",
          id: agent.id,
        }))
      );
  }, [agents.success.findByName]);

  const onSearch = (data) => {
    setSearchValue(data);
    dispatch(AgentActions.findByName(data));
  };

  return (
    <div
      className="agentAssign"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h3>Reasignación de leads</h3>
      <p>Ingresa el nombre</p>
      <AutoComplete
        value={searchValue}
        style={{ width: 200 }}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder="Nombre"
      >
        {options.map((option) => (
          <Option key={option.id} value={option.id}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img
                alt="Usuario"
                src={option.image}
                width="30px"
                height="30px"
              />
              <p>{option.value}</p>
            </div>
          </Option>
        ))}
      </AutoComplete>
      <Button
        disabled={!selectedAgent}
        onClick={() => {
          dispatch(LeadsActions.changeAgent(lead, selectedAgent));
          close();
        }}
      >
        Cambiar
      </Button>
    </div>
  );
};

AgentAssign.propTypes = {
  lead: instanceOf(Object).isRequired,
  close: func.isRequired,
};

export default AgentAssign;
