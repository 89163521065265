import { Col, Dropdown, Layout, Menu, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import HomeSvg from "../../assets/icon/home.svg"
import ListSvg from "../../assets/icon/list.svg"
import ProfileSvg from "../../assets/icon/profile.svg"
import Logo from '../../assets/images/logo.svg'
import { auth as AuthActions } from '../../services/auth/actions'
import { routes as RoutesActions } from '../../services/routes/actions'
import { useEffect } from 'react'
import MyMenu from "../../common/menu"

const { Header: HeaderAnt } = Layout

function Header() {

  let history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const routes = useSelector(state => state.routes)
  const logout = () => dispatch(AuthActions.logout())

  const changeRoute = (path) => {
    history.push(path)
    dispatch(RoutesActions.setActualRoute(path))
  }

  useEffect(() => {
    const actualMenu = history.location.pathname.split('/')[1]
    if (routes.actualPosition === undefined) {
      dispatch(RoutesActions.setActualRoute(actualMenu))
      return
    }

    if (history.location.pathname.split('/').length > 2)
      return

    const isARoute = MyMenu.find(menu => menu.key === actualMenu)
    if (isARoute === undefined) {
      history.push('')
      return
    }


    const defaultSubmenu = actualMenu + '/' + MyMenu.find(menu => menu.key === actualMenu).items.find(item => item.default).path
    if (defaultSubmenu !== '')
      history.push(defaultSubmenu)

  }, [routes, dispatch, history])

  return (
    <HeaderAnt className='header'>
      <Row>
        <Col xs={4} sm={6} md={8} lg={8} className='header__brand'>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault()
              changeRoute('')
            }}
          >
            <img src={Logo} alt='logo' />
          </a>
          <a
            href="./"
            onClick={(e) => {
              e.preventDefault();
              changeRoute('')
            }}>
            <img src={HomeSvg} alt='Home' />
          </a>
        </Col>
        <Col className='header__actions'
          xs={20} sm={18} md={16} lg={16}>
          <a
            href="./"
            className='custom-trigger-btn'
            onClick={(e) => {
              e.preventDefault();
              changeRoute('users')
            }}
          >
            <img src={ListSvg} alt="icon-agent" />
          </a>
          <Dropdown
            overlay={() => menu(t, logout, history)}
            trigger={['click']}
            overlayClassName='header__dropdown-custom'
          >
            <a
              href="./"
              className='custom-trigger-btn'
              onClick={(e) => e.preventDefault()}
            >
              <img src={ProfileSvg} alt="icon-profile" />
            </a>
          </Dropdown>
        </Col>
      </Row>
    </HeaderAnt >
  )
}


const menu = (t, logout, history) => {
  return (
    <Menu>
      <Menu.Item className='header__dropdown-custom__item header__dropdown-custom__item--hasAfter'>
        <p className='header__dropdown-custom__itemState--label'>
          {t('label.onlineStatus')}
        </p>
        <p className='header__dropdown-custom__itemState'>
          <span className='header__dropdown-custom__itemState__circle'></span>
          En linea
        </p>
      </Menu.Item>
      <Menu.Item className='header__dropdown-custom__item'>
        <span role='link'>{t('label.editProfile')}</span>
      </Menu.Item>
      <Menu.Item className='header__dropdown-custom__item'>
        <span role='link'>{t('label.tips')}</span>
      </Menu.Item>
      <Menu.Item className='header__dropdown-custom__item'>
        <span role='link'>{t('label.leads')}</span>
      </Menu.Item>
      <Menu.Item className='header__dropdown-custom__item header__dropdown-custom__item--logOut'>
        <span
          type='link'
          onClick={(e) => {
            e.preventDefault()
            history.replace('/')
            logout()
          }}
        >
          {t('label.logOut')}
        </span>
      </Menu.Item>
    </Menu>
  )
}

export default Header