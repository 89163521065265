/* eslint-disable no-plusplus */
import React, { useEffect } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "antd";
import offersActions from "services/offers/actions";
import { useDispatch, useSelector } from "react-redux";
import excelPng from "assets/images/excel.png";
import { instanceOf } from "prop-types";

const Excelexport = ({ values }) => {
  const { listFeedbackAll } = useSelector((state) => state.offers.success);
  const dispatch = useDispatch();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async (excelData) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `feedbacks${fileExtension}`);
  };

  const handleExportFinal = () => {
    dispatch(offersActions.getFeedbackAll(values));
  };

  useEffect(() => {
    if (listFeedbackAll?.length >= 1) {
      const newArrayListOffers = [];
      for (let index = 0; index < listFeedbackAll?.length; index++) {
        const element = listFeedbackAll[index];
        newArrayListOffers.push({
          Numero_de_poliza: element.policyNumber,
          insurerMadeOffer: element?.insurerMadeOffer,
          name: element?.name ?? "-",
          service: element?.service,
          stateMX: element?.stateMX,
          subBrand: element?.subBrand,
          branch: element?.branch,
          type: element?.type,
          use: element?.use,
          zipCode: element?.zipCode,
          model: element?.model,
          offerState: element?.offerStage,
          prima: element?.prima,
          quoteFile: element?.quoteFile,
          description: element?.description,
          expirationDate: element?.expirationDate,
          hasQuotation: element?.hasQuotation,
          brand: element?.brand,
        });
      }
      exportToExcel(newArrayListOffers);
    }
  }, [listFeedbackAll]);
  return (
    <Button
      className="button button-primary button-ecxel"
      onClick={handleExportFinal}
    >
      <span>Descargar Excel</span>
      <img src={excelPng} alt="ecxel" className="logo-ecxel" />
    </Button>
  );
};
Excelexport.propTypes = {
  values: instanceOf(Object).isRequired,
};
export default Excelexport;
