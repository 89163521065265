/* eslint-disable react/jsx-no-bind */
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import deleteSvg from "../../../../../../assets/images/delete-svg.svg";
import insuransersActions from "../../../../../../services/insuransers/actions";

const ModalDelete = ({ insuranceKey }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }

  const handleDeleteInsuranse = (insuranse) => {
    dispatch(insuransersActions.deleteInsurance(insuranse));
  };
  return (
    <div className="modal-styles">
      <Button className="button button-delete" onClick={openModal}>
        <img src={deleteSvg} className="delete" alt="delete" />
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general"
      >
        <h1 className="body-bold">
          Estas seguro de eliminar la aseguradora {insuranceKey.name}
        </h1>
        <div className="flex">
          <Button
            className="button button-delete"
            onClick={() => handleDeleteInsuranse(insuranceKey.insuranceKey)}
          >
            Si
          </Button>
          <Button className="button button-secundary" onClick={onCancel}>
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalDelete;
