import moment from "moment";
import { handleActions } from "redux-actions";
import * as favoritiesStorage from "common/storage/Favorities";

export const INITIAL_STATE = {
  filters: {
    IdentificadorCuenta: [],
    Ramos: [0],
    Estado: [0],
    FechaInicial: [
      moment(new Date())
        .startOf("month")
        .subtract(1, "year")
        .format("YYYY-MM-01"),
    ],
    FechaFinal: [moment(new Date()).endOf("month").format("YYYY-MM-DD")],
    NoPage: [1],
    PageSize: [20],
    Vencimiento: [0],
    covertage: [""],
    postalCode: [""],
  },
  favoritiesArrar: favoritiesStorage.get() || [],
  loading: {
    policies: false,
    listPolicies: false,
    nextRenovations: false,
    listQuotes: false,
    cover: false,
    favorities: false,
    policy: false,
    accounts: false,
    setPolicePdf: false,
  },
  error: {
    policies: false,
    listPolicies: false,
    listQuotes: false,
    favorities: false,
    cover: false,
    policy: false,
    nextRenovations: false,
    accounts: false,
    setPolicePdf: false,
  },
  success: {
    policies: undefined,
    listPolicies: undefined,
    listQuotes: undefined,
    cover: undefined,
    favorities: undefined,
    nextRenovations: undefined,
    policy: undefined,
    accounts: undefined,
    setPolicePdf: undefined,
  },
};

const reducer = handleActions(
  {
    RENOVATIONS: {
      GET_POLICIES: (state) => ({
        ...state,
        loading: { ...state.loading, policies: true },
        error: { ...state.error, policies: undefined },
      }),
      GET_POLICIES_RESPONSE: {
        next(state, { payload: policies }) {
          return {
            ...state,
            loading: { ...state.loading, policies: false },
            success: {
              ...state.success,
              policies,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, policies: message },
            success: { ...state.success, policies: undefined },
            loading: { ...state.loading, policies: false },
          };
        },
      },
      GET_NEXT_RENOVATIONS: (state) => ({
        ...state,
        loading: { ...state.loading, nextRenovations: true },
        error: { ...state.error, nextRenovations: undefined },
      }),
      GET_NEXT_RENOVATIONS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, nextRenovations: false },
            success: {
              ...state.success,
              nextRenovations: payload.nextRenovations,
            },
            error: { ...state.error, nextRenovations: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, nextRenovations: message },
            success: { ...state.success, nextRenovations: undefined },
            loading: { ...state.loading, nextRenovations: false },
          };
        },
      },
      GET_QUOTES: (state) => ({
        ...state,
        loading: { ...state.loading, listQuotes: true },
        error: { ...state.error, listQuotes: undefined },
      }),
      GET_QUOTES_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, listQuotes: false },
            success: {
              ...state.success,
              listQuotes: payload.listQuotes,
            },
            error: { ...state.error, listQuotes: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listQuotes: message },
            success: { ...state.success, listQuotes: undefined },
            loading: { ...state.loading, listQuotes: false },
          };
        },
      },
      GET_COVER: (state) => ({
        ...state,
        loading: { ...state.loading, cover: true },
        error: { ...state.error, cover: undefined },
      }),
      GET_COVER_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, cover: false },
            success: {
              ...state.success,
              cover: payload.cover,
            },
            error: { ...state.error, cover: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, cover: message },
            success: { ...state.success, cover: undefined },
            loading: { ...state.loading, cover: false },
          };
        },
      },
      GET_LIST_POLICIES: (state) => ({
        ...state,
        loading: { ...state.loading, listPolicies: true },
        error: { ...state.error, listPolicies: undefined },
      }),
      GET_LIST_POLICIES_RESPONSE: {
        next(state, { payload: listPolicies }) {
          return {
            ...state,
            loading: { ...state.loading, listPolicies: false },
            success: {
              ...state.success,
              listPolicies,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, listPolicies: message },
            success: { ...state.success, listPolicies: undefined },
            loading: { ...state.loading, listPolicies: false },
          };
        },
      },
      GET_POLICY: (state) => ({
        ...state,
        loading: { ...state.loading, policy: true },
        error: { ...state.error, policy: undefined },
      }),
      GET_POLICY_RESPONSE: {
        next(state, { payload: policy }) {
          return {
            ...state,
            loading: { ...state.loading, policy: false },
            success: {
              ...state.success,
              policy,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, policy: message },
            success: { ...state.success, policy: undefined },
            loading: { ...state.loading, policy: false },
          };
        },
      },
      GET_ALL_ACCOUNTS: (state) => ({
        ...state,
        loading: { ...state.loading, accounts: true },
        error: { ...state.error, accounts: undefined },
      }),
      GET_ALL_ACCOUNTS_RESPONSE: {
        next(state, { payload }) {
          return {
            ...state,
            loading: { ...state.loading, accounts: false },
            success: {
              ...state.success,
              accounts: payload.accounts,
              insurances: payload.insurances,
            },
            error: { ...state.error, accounts: false },
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, accounts: message },
            success: { ...state.success, accounts: undefined },
            loading: { ...state.loading, accounts: false },
          };
        },
      },
      SET_FILTERS: (state, { payload: { key, value, secondValue } }) => {
        if (key === "clean_Estado")
          return {
            ...state,
            filters: {
              ...state.filters,
              Estado: [0],
              NoPage: [1],
            },
          };
        if (key === "clean_fecha")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [
                moment(new Date())
                  .startOf("month")
                  .subtract(1, "year")
                  .format("YYYY-MM-01"),
              ],
              FechaFinal: [
                moment(new Date()).endOf("month").format("YYYY-MM-DD"),
              ],
              NoPage: [1],
            },
          };
        if (key === "FechasVencimiento")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [value],
              FechaFinal: [secondValue],
              Vencimiento: [1],
              NoPage: [1],
            },
          };
        if (key === "covertage")
          return {
            ...state,
            filters: {
              ...state.filters,
              covertage: [value],
            },
          };
        if (key === "postalCode")
          return {
            ...state,
            filters: {
              ...state.filters,
              postalCode: [value],
            },
          };
        if (key === "Fechas")
          return {
            ...state,
            filters: {
              ...state.filters,
              FechaInicial: [value],
              FechaFinal: [secondValue],
              Vencimiento: [0],
              NoPage: [1],
            },
          };
        let actualFilters = state.filters[key];
        if (key === "NoPage") {
          actualFilters = [key === "NoPage" ? actualFilters[0] + 1 : value];
        } else {
          const filterIndex = actualFilters.findIndex(
            (filter) => filter === value
          );
          if (filterIndex > -1) actualFilters.splice(filterIndex, 1);
          else actualFilters.push(value);
        }

        return {
          ...state,
          filters: {
            ...state.filters,
            [key]: actualFilters,
            NoPage: key !== "NoPage" ? [1] : actualFilters,
          },
          loading: {
            ...state.loading,
            policies: key !== "NoPage",
            insideLoader: key === "NoPage",
          },
        };
      },
      SET_FAVORITIES: (state) => ({
        ...state,
        success: { ...state.success, favorities: undefined },
        loading: { ...state.loading, favorities: true },
        error: { ...state.error, favorities: undefined },
      }),
      SET_FAVORITIES_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, favorities: false },
            success: {
              ...state.success,
              favorities: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, favorities: message },
            success: { ...state.success, favorities: undefined },
            loading: { ...state.loading, favorities: false },
          };
        },
      },
      SET_PROFILE_PHOTO: (state) => ({
        ...state,
        loading: { ...state.loading, setPolicePdf: true },
        error: { ...state.error },
      }),
      SET_PROFILE_PHOTO_RESPONSE: {
        next(state) {
          return {
            ...state,
            loading: { ...state.loading, setPolicePdf: false },
            success: {
              ...state.success,
              setPolicePdf: true,
            },
            error: INITIAL_STATE.error,
          };
        },
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, setPolicePdf: message },
            success: { ...state.success, setPolicePdf: undefined },
            loading: { ...state.loading, setPolicePdf: false },
          };
        },
      },
    },
  },
  INITIAL_STATE
);

export default reducer;
