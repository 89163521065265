/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { all, put, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import Api from "../../common/api/Api";
import { parseError } from "../../common/api/ErrorParser";
import billingActions from "./actions";

function* getAllBilling() {
  const url = "/admin/billing/all";
  const params = {
    state: "pending",
  };
  const response = yield Api.get(url, params);
  if (response.success) {
    return yield put(billingActions.getAllBillingResponse(response.detail));
  }
  const errorFormatted = parseError(response);
  return yield put(billingActions.getAllBillingResponse(errorFormatted));
}

function* billingPay({ payload }) {
  const url = "/admin/billing/pay";

  const response = yield Api.post(url, payload);
  if (response.success) {
    message.success({
      content: response.detail,
    });
    yield put(billingActions.billingPayResponse(response.detail));
    return setTimeout(() => {
      window.location.reload();
    }, "1000");
  }
  const errorFormatted = parseError(response);
  message.error({
    content: "No existe esta factura",
  });
  return yield put(billingActions.billingPayResponse(errorFormatted));
}

function* declinePay({ payload }) {
  const url = "/admin/billing/reject";

  const response = yield Api.put(url, payload);
  if (response.success) {
    message.success({
      content: response.detail,
    });
    yield put(billingActions.declinePayResponse(response.detail));
    return setTimeout(() => {
      window.location.reload();
    }, "1000");
  }
  const errorFormatted = parseError(response);
  message.error({
    content: "No existe esta factura",
  });
  return yield put(billingActions.declinePayResponse(errorFormatted));
}

function* ActionWatcher() {
  yield takeLatest(billingActions.getAllBilling, getAllBilling);
  yield takeLatest(billingActions.billingPay, billingPay);
  yield takeLatest(billingActions.declinePay, declinePay);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
