import {Form, Input, message, Spin} from 'antd'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {user as UsersActions} from '../../../../../services/users/actions'
import IconAddUser from '../../../../../assets/icon/icon-addUser.svg'
import IconAddEmail from '../../../../../assets/icon/icon-addEmail.svg'
import Icon from '@ant-design/icons'

const layout = {
  wrapperCol: {
    span: 24,
  },
}

const CreateUser = ({close}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users)

  const onFinish = (user) => {
    dispatch(UsersActions.createUser(user))
  }

  useEffect(() => {
    if (users.success.create) {
      message.success({
        content: 'Usuario registrado exitosamente',
      })
      close()
    }

    if (users.error.create)
      message.error({
        content: 'El usuario no se pudo registrar',
      })
  }, [users, close])
  const iconAddUser = () => {
    return <img src={IconAddUser} alt='' />
  }
  const iconAddEmail = () => {
    return <img src={IconAddEmail} alt='' />
  }
  return (
    <div className='create-user'>
      <h1 className='create-user__title'>Agregar usuario</h1>
      <Form {...layout} name='createUser' onFinish={onFinish}>
        <Form.Item
          className='create-user__inputs'
          name='name'
          rules={[
            {
              required: true,
              message: t('error.name'),
            },
            {
              max: 50,
              message: t('error.charactersName'),
            },
          ]}
        >
          <Input
            prefix={<Icon component={iconAddUser} />}
            placeholder={t('placeholder.inputName')}
            className='input-custom'
          />
        </Form.Item>

        <Form.Item
          className='create-user__inputs'
          name='email'
          rules={[
            {
              required: true,
              message: t('error.email'),
              type: 'email',
            },
            {
              max: 200,
              message: t('error.charactersEmail'),
            },
          ]}
        >
          <Input
            prefix={<Icon component={iconAddEmail} />}
            placeholder={t('placeholder.inputEmail')}
            className='input-custom'
          />
        </Form.Item>
        <div className='create-user__button'>
          <button
            className='btn-custom btn-custom--secundary'
            disabled={users.loading.create}
          >
            {users.loading.create && <Spin />}
            {t('button.addUser')}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CreateUser
