import React from "react";
import { Upload, message, Button, Input, Switch, Select  } from "antd";
import picture from "../../../../../assets/images/picture.png";
import layout from "../../../../../assets/images/layout.png";

const { TextArea } = Input;
const { Option } = Select;

export const TabsBase = () => {
	const props = {
		name: 'file',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};

	const onChange = (checked) => {
		console.log(`switch to ${checked}`);
	}
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	}

	return (
		<div className="TabsBase">
				<div className="content">
				<div className="content__Layout">
					<h4>Layout</h4>
					<div className="Layout">
						<img src={layout} alt="layout" className="layout--img"/>
					</div>
				</div>
				<div className="content__Layout right">
					<div className="content__Layout--head">
						<div className="cont-here">
							<div className="head-title">
								<h2>Hero</h2>
								<div className="switch">
									<Switch defaultChecked onChange={onChange} />	
									<span>Visible</span>
								</div>
							</div>
							<h4>Imagen de fondo</h4>
							<Upload className="upload" {...props}>
								<Button><img src={picture} alt="Upload"/><span>Subir imagen</span></Button>
							</Upload>
						</div>
					</div>
					<div className="content__Layout--titles">
						<div className="item">
							<h4>Línea</h4>
							<Select placeholder="Nombre" style={{ width: 120 }} onChange={handleChange}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="disabled" disabled>
									Disabled
								</Option>
								<Option value="Yiminghe">yiminghe</Option>
							</Select>
						</div>
						<div className="item">
							<h4>Título</h4>
							<Input placeholder="Seguro de autos" />
						</div>
					</div>
					<div className="textArea">
						<h4>Descripción</h4>
						<TextArea placeholder="Nisl enim, nisi, pharetra fames blandit sed. Pharetra egestas habitasse urna imperdiet gravida facilisi commodo. Quam eget habitasse vestibulum bibendum mus scelerisque." rows={4} />
					</div>
				</div>
			</div>
		</div>
	)
}