import moment from "moment";
import React from "react";
import { getI18n } from "react-i18next";
import IconUser from "../../../../../assets/icon/icon-user-table.png";
import NoOrderSvg from "../../../../../assets/icon/no-order.svg";
import SortSvg from "../../../../../assets/icon/sort_down.svg";
import showTranslatedText from "../../../../../common/utils/showTranslatedText";

const columns = (columnOrder, sort) => {
  const renderSort = (column) => {
    if (columnOrder[column].value === "ASC")
      return (
        <a
          href="./"
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img alt="Sort" src={SortSvg} />
        </a>
      );

    if (columnOrder[column].value === "DESC")
      return (
        <a
          href="./"
          onClick={(e) => {
            e.preventDefault();
            sort(column);
          }}
        >
          <img
            alt="Sort"
            src={SortSvg}
            style={{
              transform: "rotate(180deg)",
            }}
          />
        </a>
      );

    return (
      <a
        href="./"
        onClick={(e) => {
          e.preventDefault();
          sort(column);
        }}
      >
        <img alt="Sort" src={NoOrderSvg} />
      </a>
    );
  };

  return [
    {
      title: () => (
        <div>
          Nombre
          {renderSort("name")}
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a
          href="./"
          className="table-cell table-cell--name"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <img src={IconUser} alt="" />
          {record.person.name}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <a href="./" className="table-cell table-cell--email">
          {record.person.contacts[0]?.value}
        </a>
      ),
    },
    {
      title: "Agente asignado",
      dataIndex: "endPoint",
      key: "endPoint",
      render: (text, record) => (
        <p className="table-cell">{record.agent?.user.person?.name}</p>
      ),
    },
    {
      title: () => (
        <div>
          Fecha
          {renderSort("requestDate")}
        </div>
      ),
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text) => (
        <p className="table-cell">
          {moment(text).locale("es-mx").format("DD-MM-YYYY h:mm:ss a")}
        </p>
      ),
    },
    {
      title: () => (
        <div>
          Producto
          {renderSort("product")}
        </div>
      ),
      dataIndex: "product",
      key: "product",
      render: (text, record) => (
        <p>
          {showTranslatedText(
            record.desiredProduct
              ? record.desiredProduct.title
              : record.productLine.title,
            getI18n().language
          )}
        </p>
      ),
    },
    {
      title: () => (
        <div>
          Origen
          {renderSort("origin")}
        </div>
      ),
      dataIndex: "origin",
      key: "origin",
      render: (text, record) => <p>{record.origin.name}</p>,
    },
    {
      title: () => <div>Cantidad</div>,
      dataIndex: "timesRequested",
      key: "timesRequested",
      render: (text, record) => <p>{record.timesRequested}</p>,
    },
  ];
};

export default columns;
