/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { Alert, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import iconFace from "../../../../../assets/images/facebook.png";
import iconGoogle from "../../../../../assets/images/google.png";
import iconTwitter from "../../../../../assets/images/twiter.png";
import userIcon from "../../../../../assets/images/user.png";
import passIcon from "../../../../../assets/images/pass.png";
import { app } from "../../../../../common/firebase";
import {
  getSocialInfo,
  initFacebookAuthProvider,
  initGoogleAuthProvider,
  initTwitterAuthProvider,
} from "../../../../../common/firebase/auth";
import Loader from "../../../../../components/loader";
import { auth as AuthActions } from "../../../../../services/auth/actions";

const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Login = ({ changeToForgotPassword }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [oauthError, setOauthError] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.error.login) {
      message.error(t(`error.${auth.error.login}`));
      dispatch(AuthActions.clearLoginProcess());
    }
  }, [auth, t, dispatch]);

  const onFinish = (values) => dispatch(AuthActions.login(values));

  const socialLogin = async (provider, type) => {
    await app
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const loginData = getSocialInfo(
          result.additionalUserInfo.profile,
          type
        );
        onFinish(loginData);
      })
      .catch(() => {
        setOauthError(`oauth${type}`);
      });
  };

  return (
    <div className="login">
      {auth.loading.login ? (
        <div className="Loader">
          <Loader />
        </div>
      ) : (
        <div>
          <h1 className="login__redesTitle">{t("label.loginTitle")}</h1>
          <div className="card-login">
            <Row className="">
              <figure className="login__redes">
                <div className="images">
                  <div
                    className="red"
                    onClick={() =>
                      socialLogin(initFacebookAuthProvider(), "Facebook")
                    }
                  >
                    <img src={iconFace} alt="icon-facebook" />
                  </div>
                  <div
                    className="red"
                    onClick={() =>
                      socialLogin(initGoogleAuthProvider(), "Google")
                    }
                  >
                    <img src={iconGoogle} alt="icon-google" />
                  </div>
                  <div
                    className="red"
                    onClick={() =>
                      socialLogin(initTwitterAuthProvider(), "Twitter")
                    }
                  >
                    <img src={iconTwitter} alt="icon-twitter" />
                  </div>
                </div>
                <div className="login__redesSeparator">{t("label.or")}</div>
              </figure>
            </Row>
            <div className="login__form">
              <Form {...layout} name="login" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("error.email"),
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<img src={userIcon} alt="icon"/>}
                    placeholder={t("placeholder.inputEmail")}
                    className="input-custom"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("error.password"),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<img src={passIcon} alt="icon"/>}
                    placeholder={t("placeholder.inputPass")}
                    className="input-custom"
                  />
                </Form.Item>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        changeToForgotPassword();
                      }}
                      className="login__link-forgot-pass"
                    >
                      {t("button.forgetPassword")}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <button
                      type="submit"
                      className="btn-custom btn-custom--secundary btn--login"
                      disabled={auth.loading.login}
                    >
                      {t("button.login")}
                    </button>
                  </Col>
                </Row>
              </Form>
              {auth.success && auth.success.login && (
                <div className="alert">
                  <Alert
                    message="Inicio de sesión exitoso"
                    type="success"
                    showIcon
                  />
                </div>
              )}
              {oauthError && (
                <div className="alert">
                  <Alert
                    message={t(`error.${oauthError}`)}
                    type="error"
                    showIcon
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
