/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import editSvg from "assets/images/edit-purple.svg";
import insuransersActions from "services/insuransers/actions";
import { instanceOf, string } from "prop-types";

const { Option } = Select;

const ModalCreateEditInsuranse = ({ title, insuranceKey }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const { listAllInsuransers } = useSelector(
    (state) => state.insuransers.success
  );
  function openModal() {
    setIsOpen(true);
  }
  function onOk() {
    setIsOpen(false);
  }
  function onCancel() {
    setIsOpen(false);
  }
  const onFinish = (values) => {
    if (title === "edit") {
      const sentValue = {
        email: values.email,
        insuranceKey: insuranceKey.insuranceKey,
        userId: insuranceKey.key,
      };
      dispatch(insuransersActions.updateInsurance(sentValue));
    } else {
      const sentValue = {
        email: values.email,
        name: values.name,
        phone: values.phone,
        insuranceKey: values.insuranceKey,
      };
      dispatch(insuransersActions.createInsurance(sentValue));
      onOk();
    }
  };

  useEffect(() => {
    const listOptions = [];
    for (let index = 0; index < listAllInsuransers?.length; index++) {
      const element = listAllInsuransers[index];
      if (!element?.hasAccount) {
        listOptions.push({
          value: element?.key,
          label: element?.name,
          logo: element?.logoColor,
        });
      }
    }
    setOptions(listOptions);
  }, [listAllInsuransers]);

  return (
    <div className="modal-styles">
      <Button
        className={`button button-tertiary button-${title}`}
        onClick={openModal}
      >
        {title === "create" ? (
          <span>Crear Aseguradora</span>
        ) : (
          <img src={editSvg} alt="edit" />
        )}
      </Button>
      <Modal
        visible={modalIsOpen}
        footer={null}
        onOk={onOk}
        onCancel={onCancel}
        className="modal-general"
      >
        <h1 className="h3">
          {insuranceKey?.key >= 1
            ? `Editar Aseguradora ${insuranceKey.name}`
            : "Crear aseguradora."}
        </h1>
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            email: insuranceKey?.emailAdmin,
          }}
        >
          {title === "create" && (
            <>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: !(insuranceKey?.key >= 1),
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input
                  className="custom-imput"
                  placeholder="Nombre del administrador"
                />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: false,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  className="custom-imput"
                  type="number"
                  placeholder="Telefono del administrador"
                />
              </Form.Item>
              <Form.Item
                name="insuranceKey"
                rules={[
                  {
                    required: !(insuranceKey?.key >= 1),
                    message: "Please input your aseguradora!",
                  },
                ]}
              >
                <Select
                  className="select-custom"
                  placeholder="Seleecione una aseguradora"
                >
                  {options?.map((option) => (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              className="custom-imput"
              type="email"
              placeholder="Email del administrador"
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="button button-primary">
              {title === "create" ? <span>Crear</span> : <span>Editar</span>}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
ModalCreateEditInsuranse.propTypes = {
  title: string.isRequired,
  insuranceKey: instanceOf(Object).isRequired,
};
export default ModalCreateEditInsuranse;
